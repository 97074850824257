html{
  line-height: 1.15;
  -ms-text-size-adjust: 100%; }
h1{
  font-size: 2em;
  margin: 0.67em 0; }
hr{
  box-sizing: content-box;
  height: 0;
  overflow: visible; }
main{
  display: block; }
pre{
  font-family: monospace, monospace;
  font-size: 1em; }
a{
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }
abbr[title]{
  text-decoration: underline;
  text-decoration: underline dotted; }
b,
strong{
  font-weight: bolder; }
code,
kbd,
samp{
  font-family: monospace, monospace;
  font-size: 1em; }
small{
  font-size: 80%; }
img{
  border-style: none; }
svg:not(:root){
  overflow: hidden; }
button,
input,
optgroup,
select,
textarea{
  margin: 0; }
button{
  overflow: visible;
  text-transform: none; }
button,
[type="button"],
[type="reset"],
[type="submit"]{
  -webkit-appearance: button; }
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner{
  border-style: none;
  padding: 0; }
button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring{
  outline: 1px dotted ButtonText; }
fieldset{
  padding: 0.35em 0.75em 0.625em; }
input{
  overflow: visible; }
legend{
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal; }
progress{
  display: inline-block;
  vertical-align: baseline; }
select{
  text-transform: none; }
textarea{
  overflow: auto; }
[type="checkbox"],
[type="radio"]{
  box-sizing: border-box;
  padding: 0; }
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button{
  height: auto; }
[type="search"]{
  -webkit-appearance: textfield;
  outline-offset: -2px; }
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration{
  -webkit-appearance: none; }
::-webkit-file-upload-button{
  -webkit-appearance: button;
  font: inherit; }
details{
  display: block; }
summary{
  display: list-item; }
template{
  display: none; }
[hidden]{
  display: none; }
* {
  box-sizing: border-box; }

body {
  margin: 0;
  font-family: Lato, sans-serif;
  color: #3f3f3f;
  overflow-y: scroll;
  font-size: 14px;
  background-color: #f0f0f0;
  overflow-x: hidden; }
  body.has-overlay {
    overflow: hidden; }
  @media screen and (min-width: 750px) {
    body.has-overlay {
      overflow: scroll; } }

a {
  color: #3f3f3f; }

p {
  line-height: 1.45; }

li {
  line-height: 1.45; }

img {
  max-width: 100%;
  margin: auto;
  display: block; }

.rich-text img {
  max-width: 100%;
  height: auto; }

.responsive-object {
  position: relative; }

.responsive-object iframe,
.responsive-object object,
.responsive-object embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes blackout {
  0% {
    background-color: rgba(0, 0, 0, 0); }
  100% {
    background-color: rgba(0, 0, 0, 0.5); } }

@keyframes background-pulse {
  0% {
    background-color: rgba(0, 0, 0, 0.5); }
  50% {
    background-color: rgba(0, 0, 0, 0.2); }
  100% {
    background-color: rgba(0, 0, 0, 0.5); } }

@keyframes pop {
  0% {
    transform: scale(0); }
  100% {
    transform: scale(1); } }

.is-width-100-percent {
  width: 100%; }

.is-text-transform-uppercase {
  text-transform: uppercase; }

.is-padding-left-40 {
  padding-left: 40px; }

.is-padding-right-40 {
  padding-right: 40px; }

.is-grid-2fr-1fr {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 1em;
  grid-row-gap: 0px; }

.is-font-size-1-5-em {
  font-size: 1.5em; }

.is-font-size-1-em {
  font-size: 1em; }

.is-text-align-center {
  text-align: center; }

.is-orange-circle {
  background-color: #ed9e31;
  border-radius: 50%;
  width: 1em;
  height: 1em;
  display: inline-block;
  vertical-align: -0.15em;
  margin-right: 0.25em; }

@media (min-width: 500px) {
  .Section.is-padding-less {
    padding: 20px; } }

@media (min-width: 750px) {
  .Section.is-padding-less {
    padding: 20px; } }

.is-margin-0 {
  margin: 0; }

.has-border-bottom-1 tr:not(:last-child) td {
  border-bottom: 1px solid #e5e5e5; }

.has-border-bottom-1 tr th {
  border-bottom: 1px solid #e5e5e5; }

.has-line-height-2 tr td, .has-line-height-2 tr th {
  line-height: 2em; }

.has-font-size-0-75-em th,
.has-font-size-0-75-em td {
  font-size: 0.75em; }

.is-padding-bottom-0 {
  padding-bottom: 0 !important; }

.is-text-no-wrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.is-text-overlofw-ellipsis {
  white-space: nowrap; }

.u-sReadOnly {
  position: absolute !important;
  left: -10000px !important;
  top: auto !important;
  width: 1px !important;
  height: 1px !important;
  overflow: hidden !important; }

.u-listReset {
  padding: 0 !important;
  margin: 0 !important;
  list-style: none !important; }

.u-listItemReset {
  padding: 0 !important; }

.u-displayTable {
  display: table !important; }

.u-displayTableCell {
  display: table-cell !important; }

.u-displayInlineBlock {
  display: inline-block !important; }

.u-displayBlock {
  display: block !important; }

.u-padding0 {
  padding: 0px !important; }

.u-padding5 {
  padding: 5px !important; }

.u-padding10 {
  padding: 10px !important; }

.u-padding15 {
  padding: 15px !important; }

.u-padding20 {
  padding: 20px !important; }

.u-padding25 {
  padding: 25px !important; }

.u-padding30 {
  padding: 30px !important; }

.u-padding30 {
  padding: 30px !important; }

.u-padding40 {
  padding: 40px !important; }

.u-padding50 {
  padding: 50px !important; }

.u-padding60 {
  padding: 60px !important; }

.u-padding100 {
  padding: 100px !important; }

.u-padding200 {
  padding: 200px !important; }

.u-padding300 {
  padding: 300px !important; }

.u-padding400 {
  padding: 400px !important; }

.u-paddingTop0 {
  padding-top: 0px !important; }

.u-paddingTop5 {
  padding-top: 5px !important; }

.u-paddingTop10 {
  padding-top: 10px !important; }

.u-paddingTop15 {
  padding-top: 15px !important; }

.u-paddingTop20 {
  padding-top: 20px !important; }

.u-paddingTop25 {
  padding-top: 25px !important; }

.u-paddingTop30 {
  padding-top: 30px !important; }

.u-paddingTop30 {
  padding-top: 30px !important; }

.u-paddingTop40 {
  padding-top: 40px !important; }

.u-paddingTop50 {
  padding-top: 50px !important; }

.u-paddingTop60 {
  padding-top: 60px !important; }

.u-paddingTop100 {
  padding-top: 100px !important; }

.u-paddingTop200 {
  padding-top: 200px !important; }

.u-paddingTop300 {
  padding-top: 300px !important; }

.u-paddingTop400 {
  padding-top: 400px !important; }

.u-paddingLeft0 {
  padding-left: 0px !important; }

.u-paddingLeft5 {
  padding-left: 5px !important; }

.u-paddingLeft10 {
  padding-left: 10px !important; }

.u-paddingLeft15 {
  padding-left: 15px !important; }

.u-paddingLeft20 {
  padding-left: 20px !important; }

.u-paddingLeft25 {
  padding-left: 25px !important; }

.u-paddingLeft30 {
  padding-left: 30px !important; }

.u-paddingLeft30 {
  padding-left: 30px !important; }

.u-paddingLeft40 {
  padding-left: 40px !important; }

.u-paddingLeft50 {
  padding-left: 50px !important; }

.u-paddingLeft60 {
  padding-left: 60px !important; }

.u-paddingLeft100 {
  padding-left: 100px !important; }

.u-paddingLeft200 {
  padding-left: 200px !important; }

.u-paddingLeft300 {
  padding-left: 300px !important; }

.u-paddingLeft400 {
  padding-left: 400px !important; }

.u-paddingBottom0 {
  padding-bottom: 0px !important; }

.u-paddingBottom5 {
  padding-bottom: 5px !important; }

.u-paddingBottom10 {
  padding-bottom: 10px !important; }

.u-paddingBottom15 {
  padding-bottom: 15px !important; }

.u-paddingBottom20 {
  padding-bottom: 20px !important; }

.u-paddingBottom25 {
  padding-bottom: 25px !important; }

.u-paddingBottom30 {
  padding-bottom: 30px !important; }

.u-paddingBottom30 {
  padding-bottom: 30px !important; }

.u-paddingBottom40 {
  padding-bottom: 40px !important; }

.u-paddingBottom50 {
  padding-bottom: 50px !important; }

.u-paddingBottom60 {
  padding-bottom: 60px !important; }

.u-paddingBottom100 {
  padding-bottom: 100px !important; }

.u-paddingBottom200 {
  padding-bottom: 200px !important; }

.u-paddingBottom300 {
  padding-bottom: 300px !important; }

.u-paddingBottom400 {
  padding-bottom: 400px !important; }

.u-paddingRight0 {
  padding-right: 0px !important; }

.u-paddingRight5 {
  padding-right: 5px !important; }

.u-paddingRight10 {
  padding-right: 10px !important; }

.u-paddingRight15 {
  padding-right: 15px !important; }

.u-paddingRight20 {
  padding-right: 20px !important; }

.u-paddingRight25 {
  padding-right: 25px !important; }

.u-paddingRight30 {
  padding-right: 30px !important; }

.u-paddingRight30 {
  padding-right: 30px !important; }

.u-paddingRight40 {
  padding-right: 40px !important; }

.u-paddingRight50 {
  padding-right: 50px !important; }

.u-paddingRight60 {
  padding-right: 60px !important; }

.u-paddingRight100 {
  padding-right: 100px !important; }

.u-paddingRight200 {
  padding-right: 200px !important; }

.u-paddingRight300 {
  padding-right: 300px !important; }

.u-paddingRight400 {
  padding-right: 400px !important; }

.u-margin0 {
  margin: 0px !important; }

.u-margin5 {
  margin: 5px !important; }

.u-margin10 {
  margin: 10px !important; }

.u-margin15 {
  margin: 15px !important; }

.u-margin20 {
  margin: 20px !important; }

.u-margin25 {
  margin: 25px !important; }

.u-margin30 {
  margin: 30px !important; }

.u-margin30 {
  margin: 30px !important; }

.u-margin40 {
  margin: 40px !important; }

.u-margin50 {
  margin: 50px !important; }

.u-margin60 {
  margin: 60px !important; }

.u-margin100 {
  margin: 100px !important; }

.u-margin200 {
  margin: 200px !important; }

.u-margin300 {
  margin: 300px !important; }

.u-margin400 {
  margin: 400px !important; }

.u-marginTop0 {
  margin-top: 0px !important; }

.u-marginTop5 {
  margin-top: 5px !important; }

.u-marginTop10 {
  margin-top: 10px !important; }

.u-marginTop15 {
  margin-top: 15px !important; }

.u-marginTop20 {
  margin-top: 20px !important; }

.u-marginTop25 {
  margin-top: 25px !important; }

.u-marginTop30 {
  margin-top: 30px !important; }

.u-marginTop30 {
  margin-top: 30px !important; }

.u-marginTop40 {
  margin-top: 40px !important; }

.u-marginTop50 {
  margin-top: 50px !important; }

.u-marginTop60 {
  margin-top: 60px !important; }

.u-marginTop100 {
  margin-top: 100px !important; }

.u-marginTop200 {
  margin-top: 200px !important; }

.u-marginTop300 {
  margin-top: 300px !important; }

.u-marginTop400 {
  margin-top: 400px !important; }

.u-marginLeft0 {
  margin-left: 0px !important; }

.u-marginLeft5 {
  margin-left: 5px !important; }

.u-marginLeft10 {
  margin-left: 10px !important; }

.u-marginLeft15 {
  margin-left: 15px !important; }

.u-marginLeft20 {
  margin-left: 20px !important; }

.u-marginLeft25 {
  margin-left: 25px !important; }

.u-marginLeft30 {
  margin-left: 30px !important; }

.u-marginLeft30 {
  margin-left: 30px !important; }

.u-marginLeft40 {
  margin-left: 40px !important; }

.u-marginLeft50 {
  margin-left: 50px !important; }

.u-marginLeft60 {
  margin-left: 60px !important; }

.u-marginLeft100 {
  margin-left: 100px !important; }

.u-marginLeft200 {
  margin-left: 200px !important; }

.u-marginLeft300 {
  margin-left: 300px !important; }

.u-marginLeft400 {
  margin-left: 400px !important; }

.u-marginLeftAuto {
  margin-left: auto !important; }

.u-marginBottom0 {
  margin-bottom: 0px !important; }

.u-marginBottom5 {
  margin-bottom: 5px !important; }

.u-marginBottom10 {
  margin-bottom: 10px !important; }

.u-marginBottom15 {
  margin-bottom: 15px !important; }

.u-marginBottom20 {
  margin-bottom: 20px !important; }

.u-marginBottom25 {
  margin-bottom: 25px !important; }

.u-marginBottom30 {
  margin-bottom: 30px !important; }

.u-marginBottom30 {
  margin-bottom: 30px !important; }

.u-marginBottom40 {
  margin-bottom: 40px !important; }

.u-marginBottom50 {
  margin-bottom: 50px !important; }

.u-marginBottom60 {
  margin-bottom: 60px !important; }

.u-marginBottom100 {
  margin-bottom: 100px !important; }

.u-marginBottom200 {
  margin-bottom: 200px !important; }

.u-marginBottom300 {
  margin-bottom: 300px !important; }

.u-marginBottom400 {
  margin-bottom: 400px !important; }

.u-marginRight0 {
  margin-right: 0px !important; }

.u-marginRight5 {
  margin-right: 5px !important; }

.u-marginRight10 {
  margin-right: 10px !important; }

.u-marginRight15 {
  margin-right: 15px !important; }

.u-marginRight20 {
  margin-right: 20px !important; }

.u-marginRight25 {
  margin-right: 25px !important; }

.u-marginRight30 {
  margin-right: 30px !important; }

.u-marginRight30 {
  margin-right: 30px !important; }

.u-marginRight40 {
  margin-right: 40px !important; }

.u-marginRight50 {
  margin-right: 50px !important; }

.u-marginRight60 {
  margin-right: 60px !important; }

.u-marginRight100 {
  margin-right: 100px !important; }

.u-marginRight200 {
  margin-right: 200px !important; }

.u-marginRight300 {
  margin-right: 300px !important; }

.u-marginRight400 {
  margin-right: 400px !important; }

.u-marginRightAuto {
  margin-right: auto !important; }

.u-transformRotate270 {
  transform: rotate(270deg); }

.u-textDecorationUnderline {
  text-decoration: underline !important; }

.u-fontWeightBold {
  font-weight: bold !important; }

.u-fontWeightNormal {
  font-weight: normal !important; }

.u-fontSize18 {
  font-size: 16px !important; }

.u-fontSize16 {
  font-size: 16px !important; }

.u-fontSize14 {
  font-size: 14px !important; }

.u-wordBreakBreakAll {
  word-break: break-all !important; }

.u-fontStyleItalic {
  font-style: italic !important; }

.u-whiteSpaceNoWrap {
  white-space: nowrap !important; }

.u-textAlignLeft {
  text-align: left !important; }

.u-textAlignCenter {
  text-align: center !important; }

.u-textAlignRight {
  text-align: right !important; }

.u-widthFull {
  width: 100% !important; }

.u-cursorDefault {
  cursor: default !important; }

.u-cursorPointer {
  cursor: pointer !important; }

.u-colorWhite {
  color: white !important; }

.u-maxWidth0 {
  max-width: 0px !important; }

.u-maxWidth100 {
  max-width: 100px !important; }

.u-maxWidth200 {
  max-width: 200px !important; }

.u-maxWidth300 {
  max-width: 300px !important; }

.u-maxWidth400 {
  max-width: 400px !important; }

.u-maxWidth500 {
  max-width: 500px !important; }

.u-maxWidth600 {
  max-width: 600px !important; }

.u-maxWidth700 {
  max-width: 700px !important; }

.u-maxWidth800 {
  max-width: 800px !important; }

.u-borderWidth0 {
  border-width: 0 !important; }

.u-backgroundNone {
  background: none !important; }

.u-textDecorationNone {
  text-decoration: none !important; }

.u-textDecorationUnderline {
  text-decoration: underline !important; }

.u-lineHeight16 {
  line-height: 1.6 !important; }

.u-cursorDefault {
  cursor: default !important; }

.u-cursorPointer {
  cursor: pointer !important; }

.u-borderRadius0 {
  border-radius: 0px !important; }

.u-borderRadius2 {
  border-radius: 2px !important; }

.u-borderRadius4 {
  border-radius: 4px !important; }

.u-borderRadius6 {
  border-radius: 6px !important; }

.u-borderRadius8 {
  border-radius: 8px !important; }

.u-borderRadius10 {
  border-radius: 10px !important; }

.u-supportsFlex {
  display: none !important; }
  @supports (display: flex) {
    .u-supportsFlex {
      display: block !important; } }

.BarChart-svg {
  overflow: hidden; }

.Button {
  width: 100%;
  border: 0;
  border-radius: 50px;
  background-color: #7bb344;
  font-size: 14px;
  font-weight: bold;
  color: white;
  padding: 10px 15px;
  cursor: pointer;
  text-align: center;
  fill: white;
  text-decoration: none;
  display: block;
  line-height: 1; }
  @supports (display: flex) {
    .Button {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
          align-items: center;
      -ms-flex-pack: center;
          justify-content: center; } }
  .Button:hover {
    background: #557c2f; }
  @supports (display: flex) {
    .Button.is-circle {
      padding: 13px; } }
  .Button.is-inline {
    width: auto;
    display: inline-block; }
  .Button.is-white {
    color: #3f3f3f;
    background-color: white; }
    .Button.is-white:hover {
      background-color: #d9d9d9; }
  .Button.is-grey {
    background-color: #e6e6e6;
    color: #3f3f3f; }
    .Button.is-grey:hover {
      background-color: silver; }
  .Button.is-small {
    font-size: 13px;
    padding: 6px 16px; }
  .Button.is-invisible {
    fill: #3f3f3f;
    color: #3f3f3f;
    background: none; }
    .Button.is-invisible:hover {
      background: rgba(0, 0, 0, 0.15); }
  .Button.is-secondary {
    background: white;
    border: 1px solid #3F3F3F;
    color: #3F3F3F; }
    .Button.is-secondary:hover {
      background: #d9d9d9; }
  .Button.is-purple {
    background: #ad3c64;
    border: 1px solid #ad3c64;
    color: white; }
    .Button.is-purple:hover {
      background: #742843; }
  .Button.is-loading {
    cursor: default; }
    .Button.is-loading::before {
      content: '';
      background: #7bb344;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0; }
    .Button.is-loading.is-secondary::before {
      background: white; }
    .Button.is-loading::after {
      top: calc(50% - 12px);
      left: calc(50% - 10px);
      content: '';
      position: absolute;
      border-radius: 50%;
      border: 4px solid rgba(255, 255, 255, 0.3);
      border-bottom: 4px solid white;
      width: 16px;
      height: 16px;
      animation-name: spin;
      animation-duration: 0.4s;
      animation-iteration-count: infinite;
      animation-timing-function: linear; }
    .Button.is-loading.is-secondary::after {
      border-bottom: 4px solid #3f3f3f; }

.Button-loader {
  border-radius: 50%;
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-bottom: 4px solid white;
  width: 16px;
  height: 16px;
  animation-name: spin;
  animation-duration: 0.4s;
  animation-iteration-count: infinite;
  animation-timing-function: linear; }
  .Button.is-secondary .Button-loader {
    border: 4px solid rgba(0, 0, 0, 0.3);
    border-bottom: 4px solid #3f3f3f; }

.Download {
  display: inline-block;
  color: #3f3f3f;
  padding: 3px 6px 6px;
  border-radius: 5px;
  font-size: 14px; }
  .Download:hover {
    background-color: silver; }

.Download-icon {
  border: 2px solid #3f3f3f;
  border-radius: 50%;
  display: inline-block;
  fill: #3f3f3f;
  padding: 2px;
  margin-right: 5px;
  line-height: 0; }

.Input {
  border-radius: 3px;
  background-color: #fff;
  border: solid 1px #d2d2d2;
  font-size: 14px;
  padding: 8px 8px 11px;
  display: block;
  width: 100%;
  line-height: 1;
  font-family: Lato; }

.Page {
  width: 100%;
  min-height: 100vh;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column; }

.Page-contentWrap {
  background-color: #f0f0f0;
  -ms-flex-positive: 1;
      flex-grow: 1; }

.Page-content {
  padding-top: 30px; }
  @media (min-width: 750px) {
    .Page-content {
      padding-top: 0; } }

.Page-contentInner {
  max-width: 1024px;
  margin: 0 auto;
  padding: 0 5px 100px; }
  .Page-contentInner::after {
    clear: both;
    content: "";
    display: block; }
  .Page-contentInner.is-full {
    max-width: 1800px; }
  @media screen and (min-width: 750px) {
    .Page-contentInner {
      padding: 0 3rem 100px; } }
  @media (min-width: 500px) {
    .Page-contentInner {
      padding: 0 15px 100px; } }
  @media (min-width: 750px) {
    .Page-contentInner {
      padding: 0 25px 100px; } }
  @media (min-width: 900px) {
    .Page-contentInner--floatingLinks {
      padding-left: 240px; } }
  @media (min-width: 1500px) {
    .Page-contentInner--floatingLinks {
      padding-left: 0; } }
  .Page-contentInner--department {
    padding: 0 1.5rem 100px; }
    @media screen and (min-width: 750px) {
      .Page-contentInner--department {
        padding: 0 3rem 100px; } }

.Page-pre-title,
.Page-title,
.Page-mainHeading {
  color: #3f3f3f;
  font-weight: bold;
  font-size: 28px;
  line-height: 1.29;
  margin: 25px 20px 10px;
  display: block; }
  @media (min-width: 500px) {
    .Page-pre-title,
    .Page-title,
    .Page-mainHeading {
      margin: 35px 30px 20px; } }
  @media (min-width: 750px) {
    .Page-pre-title,
    .Page-title,
    .Page-mainHeading {
      margin: 45px 40px 30px; } }

.Page-pre-title {
  font-size: 1em;
  color: #979797;
  margin-bottom: 0;
  margin-top: 25px;
  font-weight: normal; }
  .Page-pre-title a {
    color: #5e5d5d; }

.Page-subHeading {
  margin: 0 0 10px;
  font-size: 18px;
  font-weight: bold;
  font-weight: 900;
  display: block; }

.Page-subHeading-link {
  margin: 0 0 10px;
  display: block; }

@media (min-width: 1050px) {
  .Page-main {
    float: left;
    width: 66.66667%;
    padding-right: 20px; } }

@media (min-width: 1050px) {
  .Page-sidebar {
    float: right;
    width: 33.33333%;
    padding-left: 20px; } }

svg {
  position: relative; }

.tooltip {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 5px;
  border-radius: 4px;
  display: none; }

.PseudoSelect {
  max-height: 36px;
  line-height: 16px; }

.PseudoSelect-list {
  min-height: 36px;
  border-radius: 3px;
  border: solid 1px #9b9b9b;
  padding-left: 0;
  display: table;
  margin: 0;
  background-color: white;
  list-style: none;
  position: relative;
  width: 100%;
  z-index: 10; }
  .PseudoSelect-list.is-open {
    z-index: 20; }
  .PseudoSelect-list.is-loading {
    display: inline-block; }

.PseudoSelect-item {
  padding-left: 0;
  display: none;
  line-height: 1.3; }
  .PseudoSelect-list.is-open .PseudoSelect-item {
    display: table-row; }
  .PseudoSelect-item.is-active {
    display: table-header-group !important; }

.PseudoSelect-radio {
  display: none; }

.PseudoSelect-label {
  cursor: pointer;
  padding: 9px 13px;
  font-size: 14px;
  display: block;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  .PseudoSelect-label::after {
    clear: both;
    content: "";
    display: block; }
  .PseudoSelect-label:hover {
    background-color: #d9d9d9; }
  .PseudoSelect-item.is-active .PseudoSelect-label::after {
    content: '';
    display: inline-block;
    float: right;
    width: 0;
    height: 0;
    position: relative;
    border: 5px solid transparent;
    border-top: 5px solid #4a4a4a;
    margin-left: 10px;
    top: 7px;
    transform: rotate(360deg);
    transition: transform 0.3s, top 0.3s; }
    .PseudoSelect-list.is-open .PseudoSelect-item.is-active .PseudoSelect-label::after {
      top: 2px;
      transform: rotate(180deg); }
  .PseudoSelect-list.is-open .PseudoSelect-item.is-active .PseudoSelect-label {
    border-bottom: 1px solid #9b9b9b; }

.PseudoSelect-text {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 330px; }

.Tooltip {
  display: inline-block;
  text-align: left;
  z-index: 110; }
  @media screen and (min-width: 800px) {
    .Tooltip {
      position: relative; } }
  .Tooltip.is-block {
    width: 100%;
    display: block; }

.Tooltip-underline {
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  display: inline-block; }
  .Tooltip-underline::after {
    content: '';
    display: block;
    position: relative;
    bottom: 0.2em;
    width: 100%;
    border-bottom: 1px dashed #4a4a4a; }
  .Tooltip-underline:hover {
    background-color: #e6e6e6; }

.Tooltip-div {
  position: relative;
  left: -5px; }

.Tooltip-modalCover {
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%; }
  @media screen and (min-width: 800px) {
    .Tooltip-modalCover {
      display: none; } }

.Tooltip-boxWrap {
  display: none;
  font-weight: normal;
  position: fixed;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: default;
  z-index: 40; }
  @media screen and (min-width: 800px) {
    .Tooltip-boxWrap.is-down {
      top: 100%; } }
  .Tooltip-boxWrap.is-open {
    display: block;
    display: -ms-flexbox;
    display: flex; }
  .Tooltip-boxWrap.is-important {
    z-index: 70; }
  @media screen and (min-width: 800px) {
    .Tooltip-boxWrap {
      background: none;
      position: absolute;
      z-index: 40;
      left: calc(-115px + 50%); }
      .Tooltip-boxWrap.is-open {
        display: none; }
      .Tooltip:hover .Tooltip-boxWrap {
        display: block; } }

.Tooltip-box {
  position: relative;
  z-index: 30; }
  @media screen and (min-width: 800px) {
    .Tooltip-box {
      position: relative; } }

.Tooltip-content {
  margin: 40px auto;
  position: static;
  max-width: 250px;
  padding: 10px 10px 20px; }
  @media screen and (min-width: 800px) {
    .Tooltip-content {
      margin: 0;
      width: 250px;
      position: absolute;
      bottom: calc(100% - 12px); }
      .Tooltip-content.is-down {
        bottom: auto;
        top: calc(100% - 12px); } }

@media screen and (min-width: 800px) {
  .Tooltip-contentWrap {
    position: relative; } }

.Tooltip-shadowBox {
  position: relative;
  padding: 10px;
  border-radius: 20px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  background: #e6e6e6;
  font-size: 13px; }

.Tooltip-infoBox {
  background-color: #fff;
  border-radius: 10px;
  padding: 15px;
  font-size: 14px; }

.Tooltip-triangle {
  display: none; }
  @media screen and (min-width: 800px) {
    .Tooltip-triangle {
      display: block;
      position: absolute;
      overflow: hidden;
      height: 25px;
      width: 40px;
      left: 95px;
      top: 100%; } }
  .Tooltip-triangle::after {
    content: '';
    position: relative;
    top: -30px;
    display: block;
    width: 40px;
    height: 40px;
    background: #e6e6e6;
    box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.5);
    transform: rotate(45deg); }
  .Tooltip-triangle.is-down {
    top: auto;
    bottom: calc(100% - 0.1em);
    transform: rotate(180deg); }

.Tooltip-title {
  padding-bottom: 5px;
  display: inline-block;
  line-height: 1.64;
  color: #4a4a4a;
  font-weight: bold; }

.Tooltip-text {
  line-height: 1.64;
  display: block;
  padding-bottom: 10px;
  color: #3f3f3f; }

.Tooltip-linkWrap {
  line-height: 1.64;
  color: #4a4a4a;
  display: block;
  cursor: pointer;
  padding: 5px 10px;
  position: relative;
  left: -5px;
  border-radius: 3px; }
  .Tooltip-linkWrap:hover {
    background-color: silver; }
  @media screen and (min-width: 800px) {
    .Tooltip-linkWrap.is-close {
      display: none; } }

.Tooltip-link {
  text-decoration: underline;
  margin-left: 3px; }

.Tooltip-closeIcon {
  padding-right: 3px; }

.Tooltip-links {
  margin: 10px 10px 0; }

.Section {
  text-align: left;
  font-size: 14px;
  display: block;
  color: #3f3f3f;
  text-decoration: none;
  width: 100%;
  padding: 20px 20px 25px;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.25);
  border: 1px solid #d2d2d2; }
  @media (min-width: 500px) {
    .Section {
      padding: 25px 30px 30px; } }
  @media (min-width: 750px) {
    .Section {
      padding: 25px 40px 30px; } }
  .Section.NoBg {
    background-color: transparent;
    box-shadow: none;
    border: none;
    padding-top: 0; }
  .Section.is-link {
    cursor: pointer; }
    .Section.is-link:hover {
      background-color: #e6e6e6; }
  .Section.is-bevel {
    padding: 10px;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    border: 1px solid #d2d2d2;
    background-color: #e6e6e6; }
    .Section.is-bevel.is-link:hover {
      background-color: #cdcdcd; }
  .Section.is-green {
    background-color: #7bb344; }
    .Section.is-green.is-link:hover {
      background-color: #628e36; }
  .Section.is-purple {
    background-color: #ad3c64; }
    .Section.is-purple.is-link:hover {
      background-color: #872f4e; }
  .Section.is-invisible {
    box-shadow: none;
    border-width: 0;
    background: none; }

.Section-card {
  padding: 20px 10px 25px;
  background: white;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.25);
  border: 1px solid #d2d2d2; }
  @media (min-width: 500px) {
    .Section-card {
      padding: 25px 20px 35px; } }
  @media (min-width: 750px) {
    .Section-card {
      padding: 25px 30px 35px; } }
  .Section.is-link:hover .Section-card {
    background-color: #d9d9d9;
    border: 1px solid #acacac; }
  .Section-card.is-dark {
    background-color: #7d7d7d;
    color: white; }
  .Section-card.is-invisible {
    box-shadow: none;
    border-width: 0;
    background: none; }

.Section-title {
  margin: 0 0 10px;
  font-size: 18px;
  font-weight: bold;
  font-weight: 900;
  display: block;
  padding: 0;
  width: 100%; }
  .Section-title.is-small {
    font-size: 14px; }

.Grid > * {
  margin-bottom: 20px; }

@supports (display: grid) {
  @media screen and (min-width: 750px) {
    .Grid {
      display: grid;
      grid-gap: 1rem; }
      .Grid > * {
        margin-bottom: 0; } }
  .Grid.has-twoColumn {
    grid-template-columns: 1fr 1fr; }
  .Grid.has-threeColumn {
    grid-template-columns: 1fr 1fr 1fr; }
  .Grid.has-rightSidebar {
    grid-template-columns: 2fr 1fr; }
  .Grid.has-leftSidebar {
    grid-template-columns: 1fr 2fr; } }

.ColumnChart-svg {
  overflow: hidden; }

.ColumnChart-svg.is-hoverable .ColumnChart-tooltip {
  opacity: 0; }
  .ColumnChart-svg.is-hoverable .ColumnChart-tooltip:hover {
    opacity: 1; }

.Highlight {
  background-color: rgba(121, 180, 60, 0.2);
  font-style: inherit; }

@supports (animation-name: spin) {
  .Loader {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 10px solid transparent;
    border-top: 10px solid #cfcfcf;
    animation-name: spin;
    animation-duration: 1s;
    animation-timing-function: linear;
    animation-iteration-count: infinite; } }

.Dropdown {
  cursor: pointer;
  padding: 9px 13px;
  font-size: 14px;
  display: block;
  width: 100%;
  border-radius: 4px; }

.Participate-info {
  margin-top: 15px; }

.Participate-button {
  background-color: #e6e6e6;
  color: #3f3f3f;
  border: 0;
  border-radius: 18px;
  font-size: 14px;
  font-weight: 700;
  padding: 8px 16px 11px 13px;
  cursor: pointer;
  text-align: center;
  margin: 0 10px 10px 0; }
  .Participate-button:hover {
    background-color: #cdcdcd; }
  .Participate-button.is-active {
    background-color: #7bb344;
    color: #fff; }

.VideoCard--fullHeight {
  height: 100%; }

.VideoCard-card--fullHeight {
  height: 100%; }

.VideoCard-container {
  color: #3f3f3f;
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column; }
  @media screen and (min-width: 768px) {
    .VideoCard-container--fullPage {
      -ms-flex-direction: row;
          flex-direction: row;
      height: 250px; } }
  @media screen and (min-width: 1200px) {
    .VideoCard-container {
      -ms-flex-direction: row;
          flex-direction: row;
      height: 250px; } }

@media screen and (min-width: 768px) {
  .VideoCard-container--compact--fullPage {
    -ms-flex-direction: unset;
        flex-direction: unset;
    height: unset; } }

@media screen and (min-width: 1200px) {
  .VideoCard-container--compact {
    -ms-flex-direction: unset;
        flex-direction: unset;
    height: unset; } }

.VideoCard-preview {
  cursor: pointer;
  -ms-flex: 0 0 150px;
      flex: 0 0 150px;
  border-radius: 15px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center; }
  @media screen and (min-width: 768px) {
    .VideoCard-preview--fullPage {
      -ms-flex-preferred-size: 300px;
          flex-basis: 300px; } }
  @media screen and (min-width: 1200px) {
    .VideoCard-preview {
      -ms-flex-preferred-size: 300px;
          flex-basis: 300px; } }

.VideoCard-icon {
  position: relative;
  padding-left: 4px;
  background: grey;
  border-radius: 50%;
  height: 80px;
  width: 80px;
  display: -ms-flexbox;
  display: flex;
  fill: white;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center; }
  .VideoCard-icon::before {
    box-sizing: content-box;
    content: "";
    position: absolute;
    width: 80px;
    height: 80px;
    border: 3px solid grey;
    left: -3px;
    top: -3px;
    border-radius: 50%;
    transform: scale(1);
    opacity: 1;
    transition: opacity 0.3s, transform 0.3s; }
    .VideoCard-preview:hover .VideoCard-icon::before {
      transform: scale(1.3);
      opacity: 0.3; }

.VideoCard-info {
  padding: 10px 20px;
  font-size: 14px; }
  @supports (display: flex) {
    .VideoCard-info {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
          flex-direction: column;
      -ms-flex-pack: center;
          justify-content: center;
      -ms-flex-align: start;
          align-items: start; } }

.VideoCard-title {
  display: inline-block;
  font-weight: 900;
  font-size: 18px;
  text-decoration: underline;
  margin-bottom: 1rem;
  cursor: pointer; }
  .VideoCard-title:hover {
    background: #d9d9d9; }

@keyframes VideoEmbed_spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

.VideoEmbed-embed {
  line-height: 0;
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  /* 16:9 */
  height: 0;
  background: black;
  margin: 10px 0 20px; }

.VideoEmbed-embedInner {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  width: 100%; }

.VideoEmbed-iframe {
  border: 1px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.VideoEmbed-modal {
  width: 100%; }

.VideoEmbed-loader {
  display: inline-block;
  width: 80px;
  height: 80px;
  border: 10px solid transparent;
  border-top: 5px solid grey;
  border-radius: 50%;
  animation-name: VideoEmbed_spin;
  animation-duration: 0.5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear; }

.VideoEmbed-dropdown {
  cursor: pointer;
  padding: 9px 13px;
  font-size: 14px;
  display: block;
  width: 100%;
  border-radius: 4px; }

.BasicContent {
  background-color: #fff;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  padding: 2rem;
  margin: 2px;
  line-height: 1.45; }
  .BasicContent.is-green {
    background-color: #7bb344; }
  .BasicContent.is-purple {
    background-color: #ad3c64; }

.BasicContent-title {
  font-weight: 900;
  line-height: normal;
  font-size: 18px;
  margin-bottom: 1rem;
  display: block; }
  .BasicContent-title.is-small {
    font-size: 14px; }

.Downloads {
  background: #7BB344;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  padding: 0.5rem; }

.Downloads-inner {
  background: white;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.25);
  border-radius: 20px; }

.Downloads-title {
  font-weight: 900;
  line-height: normal;
  font-size: 18px;
  border-bottom: 1px solid #E6E6E6;
  padding: 1rem 2rem; }

.Downloads-content {
  padding: 2rem; }

.Downloads-item {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  margin-bottom: 1rem; }

.Downloads-text {
  margin-left: 1rem; }
  .Downloads-text:hover {
    background: #d9d9d9; }

.ThumbPreview {
  overflow: hidden;
  background: #fff;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  padding: 2rem;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-direction: column;
      flex-direction: column; }
  @media screen and (min-width: 750px) {
    .ThumbPreview {
      -ms-flex-direction: row;
          flex-direction: row;
      height: 200px; } }

.ThumbPreview-image {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  width: 100%;
  min-height: 60px; }
  @media screen and (min-width: 750px) {
    .ThumbPreview-image {
      min-height: 100px; } }

.ThumbPreview-thumb {
  padding-bottom: 2rem;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center; }
  @media screen and (min-width: 750px) {
    .ThumbPreview-thumb {
      -ms-flex: 0 0 300px;
          flex: 0 0 300px;
      padding: 2rem; } }

.ThumbPreview-content {
  width: 100%; }
  @supports (display: flex) {
    .ThumbPreview-content {
      -ms-flex-positive: 1;
          flex-grow: 1;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
          flex-direction: column;
      -ms-flex-pack: center;
          justify-content: center; } }
  .ThumbPreview-content a:hover {
    background: #d9d9d9; }

.ThumbPreview-heading {
  -ms-flex-item-align: start;
      align-self: flex-start;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  font-size: 18px;
  text-decoration-line: underline;
  margin-bottom: 1rem;
  display: inline-block; }
  .ThumbPreview-heading:hover {
    background: #d9d9d9; }

.ThumbPreview-source {
  margin-bottom: 1rem; }

.ApiCta-title {
  font-size: 18px;
  font-weight: bold;
  font-weight: 900;
  line-height: 1.44;
  padding-bottom: 15px; }

.ApiCta-description {
  font-size: 14px;
  line-height: 1.86; }

.ApiCta {
  border-radius: 20px;
  background-color: #7bb344;
  border: solid 1px #d2d2d2;
  padding: 10px;
  text-decoration: none;
  color: #3f3f3f;
  display: block; }
  .ApiCta:hover {
    background-color: #557c2f; }

.ApiCta-wrap {
  border-radius: 10px;
  background-color: #fff;
  padding: 20px 25px;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.25);
  border: solid 1px #d2d2d2; }
  .ApiCta:hover .ApiCta-wrap {
    background-color: #d9d9d9; }

.Circles {
  list-style: none;
  padding: 0;
  margin: 0;
  display: inline-block;
  width: 100%; }

.Circles-item {
  text-align: center;
  padding: 20px 15px;
  border: 10px solid grey;
  border-radius: 15px;
  color: #3f3f3f;
  display: block;
  background-color: #fff;
  text-decoration: none;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.25); }
  .Circles-item:hover {
    background-color: #d9d9d9; }
  .Circles-item.is-positive {
    border-color: #7bb344; }
    .Circles-item.is-positive:hover {
      border-color: #557c2f; }
  .Circles-item.is-negative {
    border-color: #ee9f31; }
    .Circles-item.is-negative:hover {
      border-color: #c27810; }
  .Circles-item.is-prominent {
    border-color: #ad3c64; }
    .Circles-item.is-prominent:hover {
      border-color: #742843; }
  @media screen and (min-width: 750px) {
    .Circles-item {
      border-radius: 50%;
      width: 160px;
      height: 160px;
      text-align: center;
      color: #3f3f3f; } }

.Circles-float {
  display: none; }
  @media screen and (min-width: 750px) {
    .Circles-float {
      display: block;
      position: absolute;
      width: 53px;
      height: 84px;
      left: -42px;
      top: 47px; } }

.Circles-wrap {
  margin: 20px;
  border: 1px solid #d2d2d2;
  padding: 10px;
  background: #e6e6e6;
  border-radius: 15px; }
  @media screen and (min-width: 750px) {
    .Circles-wrap {
      margin: 0;
      float: left;
      border-radius: 50%;
      position: relative; }
      .Circles-wrap:nth-child(n+2) {
        margin-left: 30px; } }

.Circles-floatStroke {
  stroke: #d2d2d2; }

.Circles-floatFill {
  fill: #e6e6e6; }

.Circles::after {
  clear: both;
  content: "";
  display: block; }

.Circles-number {
  font-size: 32px;
  font-weight: bold; }

.Circles-text {
  font-size: 14px;
  font-weight: bold;
  text-decoration: underline;
  line-height: 1.2; }

.HomeLayout {
  padding-top: 30px; }
  .HomeLayout::after {
    clear: both;
    content: "";
    display: block; }

.HomeLayout-title {
  padding: 30px 20px;
  font-size: 18px;
  line-height: 1.44;
  font-weight: bold;
  display: block; }

.HomeLayout-description {
  padding-left: 20px;
  padding-top: 10px;
  line-height: 1.44;
  font-size: 18px;
  padding-bottom: 10px; }

.HomeLayout-titleWrap {
  text-align: center; }
  @media screen and (min-width: 750px) {
    .HomeLayout-titleWrap {
      padding-top: 20px;
      text-align: left; } }

.HomeLayout-main {
  width: 100%;
  padding-bottom: 30px;
  text-align: center; }
  @media screen and (min-width: 1050px) {
    .HomeLayout-main {
      text-align: left;
      float: left;
      max-width: 620px;
      padding-bottom: 0; } }

.HomeLayout-sidebar {
  width: 100%; }
  @media screen and (min-width: 1050px) {
    .HomeLayout-sidebar {
      float: right;
      max-width: 300px; } }

.HomeLayout-newsletter {
  padding-bottom: 23px; }

.Links::after {
  clear: both;
  content: "";
  display: block; }

.Links-itemWrap {
  float: left;
  padding: 5px;
  font-size: 14px;
  text-align: left;
  width: 100%; }
  @media screen and (min-width: 750px) {
    .Links-itemWrap {
      width: 50%; } }
  .Links-itemWrap--full {
    width: 100%;
    text-align: center; }

.Links-item {
  color: #3f3f3f;
  text-decoration: none;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.25);
  border: solid 1px #b9b9b9;
  padding: 10px;
  display: table;
  width: 100%; }
  .Links-item--link:hover {
    background-color: #d9d9d9; }

.Links-icon {
  display: table-cell;
  text-align: center;
  line-height: 0;
  width: 50px;
  height: 50px;
  fill: white;
  background: red;
  border-radius: 50%;
  vertical-align: middle;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.25); }
  .Links-icon--purple {
    background-color: #ad3c64; }
    .Links-item--link:hover .Links-icon--purple {
      fill: #d9d9d9;
      background-color: #742843; }
  .Links-icon--orange {
    background-color: #c66347; }
    .Links-item--link:hover .Links-icon--orange {
      fill: #d9d9d9;
      background-color: #93442e; }
  .Links-icon--darkYellow {
    background-color: #ee9f31; }
    .Links-item--link:hover .Links-icon--darkYellow {
      fill: #d9d9d9;
      background-color: #c27810; }
  .Links-icon--lightYellow {
    background-color: #fec42d; }
    .Links-item--link:hover .Links-icon--lightYellow {
      fill: #d9d9d9;
      background-color: #dda001; }
  .Links-icon--lightGreen {
    background-color: #7bb344; }
    .Links-item--link:hover .Links-icon--lightGreen {
      fill: #d9d9d9;
      background-color: #557c2f; }
  .Links-icon--darkGreen {
    background-color: #2f8c5d; }
    .Links-item--link:hover .Links-icon--darkGreen {
      fill: #d9d9d9;
      background-color: #1c5337; }
  .Links-icon--dullBlue {
    background-color: #5578c2; }
    .Links-item--link:hover .Links-icon--dullBlue {
      fill: #d9d9d9;
      background-color: #355495; }
  .Links-icon--brightBlue {
    background-color: #5793f3; }
    .Links-item--link:hover .Links-icon--brightBlue {
      fill: #d9d9d9;
      background-color: #1165ed; }

.Links-text {
  text-align: left;
  display: table-cell;
  vertical-align: middle;
  padding-left: 10px;
  font-size: 14px; }

.Links-svg {
  width: 25px;
  height: 25px; }

.Links-linkWrap {
  display: block;
  float: left;
  padding: 5px;
  width: 100%; }

.Links-link {
  display: inline-block;
  color: #3f3f3f;
  padding: 3px 6px 6px;
  border-radius: 5px;
  font-size: 14px; }
  .Links-link:hover {
    background-color: silver; }

.Links-button {
  margin: 5px 3px;
  display: inline-block;
  border: 0;
  border-radius: 18px;
  background-color: #7bb344;
  font-size: 14px;
  font-weight: bold;
  color: white;
  padding: 8px 16px 11px 13px;
  cursor: pointer;
  text-align: center;
  fill: white;
  text-decoration: none; }
  @media screen and (min-width: 900px) {
    .Links-button {
      margin: 0;
      display: inline-block;
      border-radius: 3px;
      padding: 3px;
      background: none;
      color: #3f3f3f;
      text-decoration: underline;
      font-weight: 400; } }
  .Links-button:hover {
    background-color: #d9d9d9; }

.Links-seperator {
  text-decoration: none;
  display: none; }
  @media screen and (min-width: 900px) {
    .Links-seperator {
      display: inline-block; } }

.Links-iconWrap {
  display: table-cell;
  vertical-align: middle;
  width: 50px; }

.Links-buttonList {
  padding: 10px 10px 10px 0; }
  @media screen and (min-width: 900px) {
    .Links-buttonList {
      padding: 0; } }

.NewIntro {
  font-size: 18px;
  font-weight: bold;
  line-height: 1.67;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.25);
  padding: 15px 25px 26px; }

.NewIntro-button {
  color: #3f3f3f;
  display: inline-block;
  position: relative;
  left: -6px;
  top: -3px;
  margin-top: 3px;
  padding: 3px 6px 6px;
  border-radius: 5px; }
  .NewIntro-button:hover {
    background-color: #d9d9d9; }

.Related {
  border-radius: 20px;
  background-color: #e6e6e6;
  border: solid 1px #d2d2d2;
  padding: 10px; }

.Related-wrap {
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.25);
  border: solid 1px #d2d2d2; }

.Related-title {
  line-height: 1.44;
  color: #3f3f3f;
  font-size: 18px;
  font-weight: bold;
  padding: 25px 20px 40px;
  margin: 0; }

.Related-list {
  list-style: none;
  padding: 0;
  margin: 0; }
  .Related-list::after {
    clear: both;
    content: "";
    display: block; }

.Related-item {
  float: left;
  width: 50%;
  display: block; }

.Related-link {
  font-size: 14px;
  display: block;
  height: 90px;
  padding: 20px 30px;
  text-align: center;
  color: #3f3f3f;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  font-weight: bold; }
  @supports (display: flex) {
    .Related-link {
      display: -ms-flexbox;
      display: flex; } }
  .Related-link:hover {
    background: #d9d9d9; }

.ValueBlocks::after {
  clear: both;
  content: "";
  display: block; }

.ValueBlocks-itemWrap {
  float: left;
  padding: 5px;
  width: 100%; }
  @media screen and (min-width: 750px) {
    .ValueBlocks-itemWrap {
      width: 50%; } }

.ValueBlocks-item {
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.25);
  border: solid 1px #b9b9b9;
  padding: 20px 30px;
  font-size: 19px;
  text-align: center;
  height: 120px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-direction: column;
      flex-direction: column;
  text-decoration: none; }
  .ValueBlocks-item--link {
    text-decoration: underline; }
    .ValueBlocks-item--link:hover {
      background-color: #d9d9d9; }
  .ValueBlocks-itemWrap:nth-of-type(4n+1) .ValueBlocks-item {
    color: #ad3c64; }
  .ValueBlocks-itemWrap:nth-of-type(4n+2) .ValueBlocks-item {
    color: #5793f3; }
  .ValueBlocks-itemWrap:nth-of-type(4n+3) .ValueBlocks-item {
    color: #7bb344; }
  .ValueBlocks-itemWrap:nth-of-type(4n+4) .ValueBlocks-item {
    color: #ee9f31; }

.ValueBlocks-title {
  font-weight: bold;
  font-weight: 900; }

.ExtraFooter {
  background-color: white; }

.ExtraFooter-title {
  font-size: 18px;
  font-weight: bold;
  font-weight: 900;
  color: #3f3f3f;
  padding-bottom: 20px; }

.ExtraFooter-item {
  display: block; }
  @media screen and (min-width: 800px) {
    .ExtraFooter-item {
      margin-right: 115px;
      float: left; } }

.ExtraFooter-list {
  padding-bottom: 85px; }
  .ExtraFooter-list::after {
    clear: both;
    content: "";
    display: block; }

.ExtraFooter-image {
  width: 100%;
  max-width: 175px; }
  .ExtraFooter-image--larger {
    max-width: 200px; }

.ExtraFooter-wrap {
  margin: 0 auto;
  max-width: 1024px;
  padding: 0 40px; }

.Footer {
  background-color: white;
  border-top: 10px solid #3f3f3f;
  font-size: 14px;
  line-height: 2.14; }

.Footer-container {
  margin: 0;
  padding: 0; }
  .Footer-container::after {
    clear: both;
    content: "";
    display: block; }

.Footer-section {
  display: block;
  width: 100%; }
  @media screen and (min-width: 650px) {
    .Footer-section {
      float: left;
      width: 33.33333%;
      padding-right: 10px; }
      .Footer-section.is-double {
        width: 100%; } }
  @media screen and (min-width: 900px) {
    .Footer-section {
      float: left;
      width: 20%;
      padding-right: 10px; }
      .Footer-section.is-double {
        width: 40%; } }

.Footer-title {
  font-size: 18px;
  font-weight: bold;
  font-weight: 900;
  margin-bottom: 0;
  padding-bottom: 8px; }

.Footer-wrap {
  padding: 25px 45px 40px;
  max-width: 1024px;
  margin: 0 auto; }

.Footer-link {
  font-size: 14px;
  text-decoration: none;
  color: #4a4a4a;
  display: inline-block;
  border-radius: 3px;
  padding: 2px 10px;
  position: relative;
  left: -10px; }
  .Footer-link:hover {
    background-color: #e3e3e3; }

.Footer-list {
  list-style: none;
  padding-left: 0;
  margin-top: 0; }

.Footer-newsletter {
  margin: 5px 0 15px; }
  @media screen and (min-width: 650px) {
    .Footer-newsletter {
      display: table;
      width: 80%; } }

.Footer-button {
  width: 100px;
  padding-top: 10px; }
  @media screen and (min-width: 650px) {
    .Footer-button {
      display: table-cell;
      padding-top: 0;
      padding-left: 10px;
      vertical-align: top; } }

.Footer-input {
  display: table-cell; }

.SocialIcon {
  line-height: 0;
  width: 20px;
  height: 20px; }

.Footer-socialMedia {
  position: relative;
  left: -15px; }

.HeaderBar {
  background: white;
  display: table;
  width: 100%;
  padding: 0 10px; }
  @media screen and (min-width: 900px) {
    .HeaderBar {
      display: block;
      border-bottom: 5px solid #3f3f3f; } }

.HeaderBar-wrap {
  display: table-row; }
  @media screen and (min-width: 900px) {
    .HeaderBar-wrap {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
      margin: 0 auto;
      max-width: 900px; } }

.HeaderBar-navBarWrap {
  display: table-cell;
  vertical-align: middle; }
  @media screen and (min-width: 900px) {
    .HeaderBar-navBarWrap {
      display: block;
      width: 100%;
      -ms-flex-order: 1;
          order: 1;
      padding: 0; } }

.HeaderBar-logoWrap {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  width: 100%;
  padding: 3px; }
  @media screen and (min-width: 900px) {
    .HeaderBar-logoWrap {
      display: block;
      width: 256px;
      padding: 10px 12px 12px 0; } }

.HeaderBar-searchWrap {
  display: table-cell;
  vertical-align: middle; }
  @media screen and (min-width: 900px) {
    .HeaderBar-searchWrap {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-positive: 1;
          flex-grow: 1;
      -ms-flex-pack: center;
          justify-content: center;
      -ms-flex-align: center;
          align-items: center; } }

.HeaderBar-logoTitle {
  font-weight: bold; }

.Logo {
  width: 160px;
  height: 46px; }
  @media screen and (min-width: 900px) {
    .Logo {
      width: 244px;
      height: 75px; } }
  .Logo:hover {
    fill: #3f3f3f; }

.Logo-green {
  fill: #ED9E31; }
  .Logo:hover .Logo-green {
    fill: #3f3f3f; }

.Logo-orange {
  fill: #76B643; }
  .Logo:hover .Logo-orange {
    fill: #3f3f3f; }

.NavBar-mobileWrap {
  padding: 20px; }
  .NavBar-mobileWrap:active {
    background-color: #d9d9d9; }
  @media screen and (min-width: 900px) {
    .NavBar-mobileWrap {
      padding: 0;
      display: none; } }

.NavBar-container {
  -ms-flex-align: center;
      align-items: center;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  position: fixed;
  z-index: 90;
  transform: translateX(-110%);
  transition: transform 0.3s; }
  .NavBar-container.is-active {
    transform: translateX(0); }
  @media screen and (min-width: 900px) {
    .NavBar-container {
      height: auto;
      position: static;
      transform: none; }
      .NavBar-container.is-active {
        animation: none; } }

.NavBar-modalCover {
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  position: fixed;
  display: none;
  background: rgba(0, 0, 0, 0.2); }
  .NavBar-modalCover.is-active {
    display: block; }
    @media screen and (min-width: 900px) {
      .NavBar-modalCover.is-active {
        display: none; } }

.NavBar-wrap {
  height: 100%;
  width: 100%;
  max-width: 320px;
  border-radius: 0 3px 3px 0;
  background-color: #fff;
  box-shadow: 0 2px 21px 0 rgba(0, 0, 0, 0.5);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column; }
  @media screen and (min-width: 900px) {
    .NavBar-wrap {
      max-width: 100%;
      box-shadow: none;
      background: none;
      height: auto; } }

.NavBar-list {
  overflow-y: scroll;
  background-color: #f0f0f0;
  list-style: none;
  width: 100%;
  max-width: 1024px;
  margin: 0;
  padding-left: 0; }
  .NavBar-list::after {
    clear: both;
    content: "";
    display: block; }
  @media screen and (min-width: 900px) {
    .NavBar-list {
      overflow-y: visible;
      background: none;
      margin: 0 auto; } }

.NavBar-icon {
  display: none; }
  @media screen and (min-width: 900px) {
    .NavBar-icon {
      display: block;
      width: 24px;
      height: 24px; } }

.NavBar-iconWrap {
  display: none;
  padding: 6px 7px; }
  .Navbar-link:not(.is-active):hover .NavBar-iconWrap {
    border-radius: 18px;
    background: #d9d9d9; }
  @media screen and (min-width: 900px) {
    .NavBar-iconWrap {
      display: block; } }

.NavBar-item {
  line-height: 1;
  margin-left: 0;
  display: block; }
  @media screen and (min-width: 900px) {
    .NavBar-item {
      list-style: none;
      float: left; }
      .NavBar-item.is-right {
        float: right; } }

.Navbar-Tag-new {
  border-radius: 6px;
  background-color: #76B643;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  line-height: 16.8px;
  padding: 2px 4px; }

.NavBar-link {
  display: block;
  border-bottom: solid 1px #b9b9b9;
  color: #4a4a4a;
  text-decoration: none;
  padding: 12px 22px;
  font-size: 16px; }
  @media screen and (min-width: 900px) {
    .NavBar-link {
      border-radius: 18px 18px 0 0;
      padding: 6px 6px;
      fill: #4a4a4a;
      -webkit-user-select: none;
          -ms-user-select: none;
              user-select: none;
      font-weight: bold;
      font-size: 16px;
      border-bottom-width: 0; } }
  .NavBar-link:not(.has-subLinks).is-active {
    font-weight: bold; }
  @media screen and (min-width: 900px) {
    .NavBar-link.is-active {
      border: 18px 18px 0 0;
      background-color: #3f3f3f;
      color: white;
      fill: white; }
      .NavBar-link.is-active.is-darkBackground {
        background-color: #f9f9f9; } }

.NavBar-subItems {
  list-style: none;
  padding-left: 0; }
  @media screen and (min-width: 900px) {
    .NavBar-subItems {
      width: 200px;
      background-color: #f5f6f6;
      padding: 20px;
      list-style: none;
      text-align: right; } }

.NavBar-subLink {
  padding: 12px 22px;
  font-size: 16px;
  border-bottom: solid 1px #b9b9b9;
  background: white;
  display: block;
  cursor: pointer;
  padding-left: 36px;
  text-decoration: none;
  color: #4a4a4a; }
  @media screen and (min-width: 900px) {
    .NavBar-subLink {
      display: inline-block;
      font-weight: normal;
      margin-bottom: 10px;
      padding: 8px 15px;
      border-radius: 2px;
      background: none;
      border-bottom-width: 0; } }
  .NavBar-subLink:not(.is-active):hover {
    background-color: #e6e6e6; }
    @media screen and (min-width: 900px) {
      .NavBar-subLink:not(.is-active):hover {
        background-color: #dadede; } }
  .NavBar-subLink.is-active {
    font-weight: bold; }
    @media screen and (min-width: 900px) {
      .NavBar-subLink.is-active {
        cursor: default; } }

@media screen and (min-width: 900px) {
  .NavBar-text {
    display: inline-block;
    padding: 10px 8px; }
    .NavBar-link:not(.is-active):hover .NavBar-text {
      border-radius: 18px;
      background: #d9d9d9; }
    .NavBar-text.is-mobileOnly {
      display: none; } }

@media screen and (min-width: 900px) {
  .NavBar-subItemsWrap {
    display: none; }
    .NavBar-subItemsWrap.is-currentPage {
      display: block;
      position: absolute;
      left: 10;
      top: 325px; }
      .NavBar-subItemsWrap.is-currentPage.is-fixed {
        position: fixed;
        top: 50px; } }
    @media screen and (min-width: 900px) and (min-width: 1050px) {
      .NavBar-subItemsWrap.is-currentPage {
        left: calc(50vw - (1024px / 2)); } }
    @media screen and (min-width: 900px) and (min-width: 1500px) {
      .NavBar-subItemsWrap.is-currentPage {
        left: calc(50vw - ((1024px / 2) + 230px)); } }

.NavBar-expand {
  border-bottom: solid 1px #b9b9b9;
  text-align: right;
  padding: 17.5px 16.5px;
  cursor: pointer; }
  .NavBar-expand:hover {
    background-color: #cccccc; }
  @media screen and (min-width: 900px) {
    .NavBar-expand {
      display: none; } }

.NavBar-expandText {
  font-weight: bold;
  display: inline-block;
  vertical-align: middle;
  font-size: 16px;
  padding: 0 11px; }

.NavBar-expandIcon {
  vertical-align: middle;
  display: inline-block;
  width: 20px;
  height: 25px;
  border: 2px solid #4a4a4a;
  border-radius: 3px;
  position: relative; }
  .NavBar-expandIcon::after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border: 5px solid transparent;
    border-right: 5px solid black;
    position: absolute;
    top: 5px;
    left: 0; }

.NavBar-mobileTrigger {
  width: 25px;
  height: 25px; }

.Search {
  width: 100%; }

.Search-float {
  display: none;
  padding: 20px 30px;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 109; }
  .Search-float.is-focused {
    display: block; }
  @media screen and (min-width: 900px) {
    .Search-float {
      display: block;
      position: relative;
      padding: 0; } }

.Search-wrap {
  position: relative; }

.Search-formWrap {
  border-radius: 18px;
  overflow: hidden;
  border: solid 1px #d2d2d2;
  display: none; }
  @media screen and (min-width: 900px) {
    .Search-formWrap {
      display: block; } }
  .Search-wrap.is-focused .Search-formWrap {
    display: block;
    border: solid 1px #79b43c;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5); }

.Search-form {
  overflow: hidden;
  border-radius: 3px 3px 0 0;
  display: table;
  width: 100%;
  margin: 0; }
  .Search-form.is-loading {
    display: inline-block;
    height: 44px; }

.Search-keywords {
  display: table-cell;
  font-size: 14px;
  padding: 13px;
  border: none;
  width: 100%;
  height: 100%;
  vertical-align: top;
  outline: none; }

.Search-action {
  display: table-cell;
  width: 1px;
  vertical-align: top;
  background-color: #79b43c; }
  .Search-action:hover {
    background-color: #527b29; }

.Search-button {
  padding: 12px 16px;
  border: none;
  display: block;
  width: 100%;
  cursor: pointer;
  background: none; }

.Search-icon {
  width: 18px;
  height: 17px;
  fill: white;
  position: relative;
  right: 2px; }
  .Search-icon--large {
    width: 25px;
    height: 25px; }

.Search-results {
  border-radius: 18px;
  background-color: white;
  font-size: 14px;
  line-height: 24px;
  padding: 13px 8px;
  position: absolute;
  top: 100%;
  width: 100%;
  border-left: solid 1px #9b9b9b;
  border-right: solid 1px #9b9b9b;
  border-bottom: solid 1px #9b9b9b;
  overflow: hidden;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  transform: scaleY(0);
  transition: transform 0.2s;
  transform-origin: top; }
  .Search-results.is-open {
    transform: scaleY(1); }

.Search-title {
  font-weight: bold;
  padding: 0 10px; }

.Search-list {
  padding-left: 0;
  list-style: none; }

.Search-item {
  text-decoration: none; }

.Search-error {
  padding: 0 10px; }

.Search-loading {
  padding: 0 10px; }

@supports (animation: spin 0.5s linear 0s infinite) {
  .Search-loading::after {
    /* stylelint-disable-line plugin/no-unsupported-browser-features */
    position: relative;
    top: 8px;
    left: 10px;
    content: '';
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-right: 3px solid #4a4a4a;
    animation: spin 0.5s linear 0s infinite; } }

.Search-link {
  display: block;
  text-decoration: none;
  color: #4a4a4a;
  padding: 5px 10px;
  border-radius: 5px; }
  .Search-link:hover {
    background-color: #d9d9d9; }

.Search-showing {
  width: 100%;
  display: inline-block;
  padding-left: 10px; }
  @media screen and (min-width: 400px) {
    .Search-showing {
      padding-left: 5px;
      width: auto; } }

.Search-pseudoLink {
  text-decoration: underline;
  cursor: pointer;
  display: inline-block; }

.Search-mobileIcon {
  vertical-align: middle;
  display: table-cell; }
  @media screen and (min-width: 900px) {
    .Search-mobileIcon {
      display: none; } }

.Search-searchIcon {
  vertical-align: middle;
  display: table-cell; }
  @media screen and (min-width: 900px) {
    .Search-searchIcon {
      display: none; } }

.Search-mobileSvg {
  width: 25px;
  height: 25px; }

.Search-expand {
  border-bottom: solid 1px #b9b9b9;
  text-align: right;
  padding: 17.5px 16.5px;
  cursor: pointer; }
  .Search-expand:hover {
    background-color: #cccccc; }
  @media screen and (min-width: 900px) {
    .Search-expand {
      display: none; } }

.Search-expandText {
  font-weight: bold;
  display: inline-block;
  vertical-align: middle;
  font-size: 16px;
  padding: 0 11px; }

.Search-expandIcon {
  vertical-align: middle;
  display: inline-block;
  width: 20px;
  height: 25px;
  border: 2px solid #4a4a4a;
  border-radius: 3px;
  position: relative; }
  .Search-expandIcon::after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border: 5px solid transparent;
    border-right: 5px solid black;
    position: absolute;
    top: 5px;
    left: 0; }

.Search-modalCover {
  display: none;
  background: rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }
  .Search-modalCover.is-focused {
    display: block; }

.Search-mobile {
  padding: 20px; }
  .Search-mobile:active {
    background-color: #d9d9d9; }
  @media screen and (min-width: 900px) {
    .Search-mobile {
      padding: 0;
      display: none; } }

@media screen and (min-width: 900px) {
  .SubLinks {
    display: none; }
    .SubLinks.is-currentPage {
      display: block;
      position: absolute;
      left: 10px;
      top: 267px; }
      .SubLinks.is-currentPage.is-fixed {
        position: fixed;
        top: 50px; } }
    @media screen and (min-width: 900px) and (min-width: 1050px) {
      .SubLinks.is-currentPage {
        left: calc(50vw - (1024px / 2)); } }
    @media screen and (min-width: 900px) and (min-width: 1500px) {
      .SubLinks.is-currentPage {
        left: calc(50vw - ((1024px / 2) + 230px)); } }

/* Overrides for dropdown on wide viewport */
@media screen and (min-width: 900px) {
  .NavBar-link.dropdown:hover + .SubLinks, .SubLinks:hover {
    display: block;
    position: absolute;
    z-index: 1; } }

.SubLinks-subItems {
  list-style: none;
  padding-left: 0; }
  @media screen and (min-width: 900px) {
    .SubLinks-subItems {
      background-color: #fff;
      border-radius: 20px;
      box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.25);
      width: 200px;
      list-style: none;
      text-align: right;
      padding: 18px 0 18px 20px; } }

.SubLinks-subLink {
  padding: 12px 22px;
  font-size: 16px;
  border-bottom: solid 1px #b9b9b9;
  background: white;
  display: block;
  cursor: pointer;
  padding-left: 36px;
  text-decoration: none;
  color: #4a4a4a;
  line-height: 1; }
  @media screen and (min-width: 900px) {
    .SubLinks-subLink {
      font-weight: normal;
      padding: 13px 18px 16px;
      margin-left: 20px;
      background: none;
      border-bottom-width: 0;
      border-radius: 10px 0 0 10px; } }
  .SubLinks-subLink:not(.is-active):hover {
    background-color: #e6e6e6; }
    @media screen and (min-width: 900px) {
      .SubLinks-subLink:not(.is-active):hover {
        background-color: #dadede; } }
  .SubLinks-subLink.is-active {
    font-weight: bold;
    font-weight: 900;
    background-color: #f0f0f0; }
    @media screen and (min-width: 900px) {
      .SubLinks-subLink.is-active {
        cursor: default; } }

.YearSelect {
  background: #3f3f3f;
  color: white; }
  @media screen and (min-width: 900px) {
    .YearSelect {
      color: #3f3f3f;
      background-color: #f0f0f0;
      padding-top: 15px; } }

.YearSelect-fixed {
  overflow: hidden;
  background: #3f3f3f;
  color: white;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transform: translateY(-147px);
  transition: transform 0.4s;
  z-index: 99999999999;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12); }
  .YearSelect-fixed.is-active {
    transform: translateY(0);
    overflow: visible; }

.YearSelect-instance {
  text-align: center;
  padding: 0 20px; }

.YearSelect-title {
  vertical-align: middle;
  padding-right: 20px;
  display: inline-block;
  font-family: "Verdana", sans-serif;
  text-transform: capitalize;
  font-size: 11px; }
  @media screen and (min-width: 400px) {
    .YearSelect-title {
      font-size: 16px;
      text-transform: none;
      font-family: "Lato", sans-serif; } }

.YearSelect-titleExtra {
  display: none; }
  @media screen and (min-width: 400px) {
    .YearSelect-titleExtra {
      display: inline; } }

.YearSelect-content {
  line-height: 1.1;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  position: relative;
  max-width: 100px;
  height: 48px; }
  @media screen and (min-width: 900px) {
    .YearSelect-content {
      height: auto;
      max-width: 560px; } }

.YearSelect-bar {
  padding-left: 0;
  margin: 5px 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  z-index: 30;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  border-radius: 18px;
  background-color: #fff;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.25);
  border: solid 1px #d2d2d2; }
  .YearSelect-bar::after {
    clear: both;
    content: "";
    display: block; }
  .YearSelect-bar.is-loading {
    height: 38px; }
  @media screen and (min-width: 900px) {
    .YearSelect-bar {
      position: static;
      display: block; } }
  @media screen and (min-width: 950px) {
    .YearSelect-bar {
      width: auto; } }

.YearSelect-item {
  cursor: pointer;
  list-style: none;
  float: left;
  width: 100%;
  display: none;
  -ms-flex-order: 2;
      order: 2;
  line-height: 1.1; }
  @media screen and (min-width: 900px) {
    .YearSelect-item {
      display: block;
      width: 25%; }
      .YearSelect-item::after {
        display: none !important; } }
  .YearSelect-item.is-active {
    -ms-flex-order: 1;
        order: 1;
    font-weight: bold;
    border: none;
    cursor: pointer;
    display: inline-block;
    background-color: #ad3c64;
    border-radius: 18px; }
    .YearSelect-bar.is-open .YearSelect-item.is-active {
      border-bottom: 1px solid #4a4a4a; }
    .YearSelect-item.is-active:first-child {
      border-left-width: 0; }
    .YearSelect-item.is-active:last-child {
      border-right-width: 0; }
    .YearSelect-item.is-active::after {
      content: '';
      display: inline-block;
      width: 0;
      height: 0;
      border: 5px solid transparent;
      border-top: 5px solid white;
      margin-left: 5px;
      position: relative;
      top: 15px;
      transition: top 0.3s, transform 0.3s;
      transform: rotate(0deg); }
    .YearSelect-bar.is-open .YearSelect-item.is-active::after {
      transform: rotate(180deg);
      top: 9px; }
    @media screen and (min-width: 900px) {
      .YearSelect-item.is-active {
        cursor: default;
        background: none;
        display: block;
        border-bottom-width: 0 !important;
        border-radius: 0 18px 18px 0; } }
  .YearSelect-bar.is-open .YearSelect-item {
    display: block; }

.YearSelect-link {
  vertical-align: top;
  width: auto;
  font-size: 14px;
  text-decoration: none;
  padding: 10px 0;
  display: block;
  color: #4a4a4a;
  text-align: center;
  overflow: hidden; }
  .YearSelect-item:first-child .YearSelect-link {
    border-radius: 15px 0 0 15px; }
  .YearSelect-item:last-child .YearSelect-link {
    border-radius: 15px; }
    @media screen and (min-width: 900px) {
      .YearSelect-item:last-child .YearSelect-link {
        border-radius: 0 15px 15px 0; } }
  .YearSelect-item.is-active .YearSelect-link {
    color: white;
    display: inline-block;
    background-color: #ad3c64; }
  .YearSelect-bar:not(.is-open) .YearSelect-item:not(.is-active) .YearSelect-link:hover {
    background-color: #d9d9d9; }
  @media screen and (min-width: 900px) {
    .YearSelect-link {
      width: 100%; } }

.Modals {
  z-index: 10000; }

.Modals-inner {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  z-index: 1000;
  padding: 20px; }

.Modals-overlay {
  background: rgba(0, 0, 0, 0.4);
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0; }
  @supports (transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1)) {
    .Modals-overlay {
      transition: opacity 0.1s; } }
  .Modals-inner.is-enter .Modals-overlay {
    opacity: 0; }
  .Modals-inner.is-enter-active .Modals-overlay {
    opacity: 1; }
  .Modals-inner.is-leave .Modals-overlay {
    opacity: 1; }
  .Modals-inner.is-leave-active .Modals-overlay {
    opacity: 0; }

.Modals-boxWrap {
  width: 100%;
  margin: 40px auto;
  position: relative;
  max-width: 700px;
  border-radius: 20px;
  background-color: #e6e6e6;
  padding: 10px; }
  @supports (transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1)) {
    .Modals-boxWrap {
      transition: transform 0.3s;
      transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1); } }
  .Modals-inner.is-enter .Modals-boxWrap {
    transform: scale(0); }
  .Modals-inner.is-enter-active .Modals-boxWrap {
    transform: scale(1); }
  .Modals-inner.is-leave .Modals-boxWrap {
    transform: scale(1); }
  .Modals-inner.is-leave-active .Modals-boxWrap {
    transform: scale(0); }

.Modals-box {
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.25); }

.Modals-heading {
  font-size: 18px;
  font-weight: 900;
  padding: 15px 40px 15px 20px;
  border-bottom: 1px solid #e6e6e6; }

.Modals-content {
  padding: 15px 20px; }

.Modals-close {
  cursor: pointer;
  position: absolute;
  background: #e6e6e6;
  padding: 10px;
  border-radius: 50%;
  fill: #c66347;
  top: -12px;
  right: -12px;
  border: 4px solid white;
  line-height: 0;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.25); }
  .Modals-close:hover {
    fill: #93442e;
    background: silver;
    border: 4px solid #d9d9d9; }
  .Modals-close:focus {
    outline: 0;
    fill: #93442e;
    background: silver;
    border: 4px solid #d9d9d9; }
  .Modals-close:active {
    top: -11px;
    right: -13px;
    box-shadow: none; }

.Events {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-align: center;
      align-items: center; }

.Events-blockWrap {
  padding: 10px 0;
  width: 100%; }
  @media screen and (min-width: 900px) {
    .Events-blockWrap {
      display: inline-block;
      width: 49%; }
      @supports (display: flex) {
        .Events-blockWrap {
          width: 50%; } }
      .Events-blockWrap:nth-of-type(2n) {
        padding-left: 10px; }
      .Events-blockWrap:nth-of-type(2n+1) {
        padding-right: 10px; } }

.Events-block {
  text-decoration: none;
  color: #3f3f3f;
  position: relative;
  width: 100%;
  display: table;
  background-color: #fff;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  overflow: hidden; }
  @media screen and (min-width: 900px) {
    .Events-block {
      height: 240px; } }

.Events-infoWrap {
  display: table-row; }

.Events-info {
  padding: 20px; }

.Events-type {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 10px;
  line-height: 1.45; }

.Events-description {
  margin-top: 16px;
  font-size: 14px;
  line-height: 1.45;
  margin-bottom: 10px; }

.Events-buttons {
  margin-top: 10px; }

.Events-pastWrap {
  display: table-row;
  height: 1px; }

.Events-past {
  font-size: 13px;
  background-color: #e6e6e6;
  padding: 10px 20px 20px; }

.Events-image {
  max-width: 100px;
  margin-right: 10px;
  margin-bottom: 10px;
  border: 5px solid white;
  display: inline-block;
  outline: 1px solid grey;
  cursor: pointer;
  position: relative;
  line-height: 0; }
  .Events-image:hover {
    border: 5px solid #d9d9d9;
    filter: grayscale(100%); }

.Events-icon {
  fill: grey;
  margin-right: 5px;
  display: inline-block; }

.Events-link {
  line-height: 0;
  font-size: 0;
  display: inline-block; }

.Events-line {
  padding: 2px 0;
  font-style: italic; }

.DeptControls {
  display: -ms-flexbox;
  display: flex; }
  .DeptControls::after {
    clear: both;
    content: "";
    display: block; }

.DeptControls-keywords {
  width: 100%;
  max-width: 600px; }
  @media screen and (min-width: 1000px) {
    .DeptControls-keywords {
      width: 400px;
      float: left; } }

.DeptControls-in {
  float: left;
  padding: 10px;
  font-size: 14px; }

.DeptControls-dropdown {
  float: left;
  width: 100%; }

.DeptControls-itemWrap {
  padding: 10px 0; }
  .DeptControls-itemWrap::after {
    clear: both;
    content: "";
    display: block; }
  @media screen and (min-width: 1000px) {
    .DeptControls-itemWrap {
      float: left;
      padding: 0; } }

.DeptGroup {
  border-radius: 20px;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.25);
  background-color: white;
  padding: 23px 21px;
  position: relative;
  overflow: hidden; }

.DeptGroup-title {
  font-weight: bold;
  font-weight: 900;
  font-size: 18px;
  padding-bottom: 23px;
  margin: 0; }

.DeptGroup-item {
  font-size: 14px; }

.DeptGroup-mapWrap {
  position: relative; }

.DeptGroup-map {
  position: absolute;
  z-index: 5;
  right: 0;
  top: 0;
  height: 100%;
  width: 100%;
  text-align: right;
  padding: 5px;
  fill: #f5f5f5; }

.DeptGroup-svg {
  height: 100%;
  width: auto; }

.DeptGroup-list {
  list-style: none;
  padding-left: 0;
  margin: 0; }
  @media screen and (min-width: 750px) {
    .DeptGroup-list--doubleRow {
      column-count: 2; } }

.DeptGroup-wrap {
  position: relative;
  z-index: 10; }

.DeptGroup-link {
  text-decoration: none;
  color: #4a4a4a;
  display: inline-block;
  padding: 1px 6px;
  position: relative;
  left: -6px;
  border-radius: 3px; }
  .DeptGroup-link:hover {
    background-color: rgba(0, 0, 0, 0.15); }

.DeptSearch-keywords {
  border: solid 1px #9b9b9b;
  border-radius: 3px;
  padding: 9px 13px;
  font-size: 14px;
  width: 100%;
  max-width: 300px;
  display: inline-block; }
  .DeptSearch-keywords.is-loading {
    height: 36px; }

.DeptSearch-groupWrap {
  padding-bottom: 20px; }

.DeptSearch-select {
  border-radius: 3px;
  border: solid 1px #9b9b9b;
  padding-left: 0;
  display: table;
  min-width: 170px;
  margin: 0;
  background-color: white; }

.DeptSearch-filter {
  display: inline-block;
  vertical-align: top;
  max-height: 36px; }

.DeptSearch-label {
  cursor: pointer;
  padding: 9px 13px;
  font-size: 14px;
  display: block;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  .DeptSearch-label:hover {
    background-color: #d9d9d9; }
  .DeptSearch-select.is-open .DeptSearch-item.is-active .DeptSearch-label {
    border-bottom: 1px solid #4a4a4a; }
  .DeptSearch-item.is-active .DeptSearch-label::after {
    content: '';
    display: inline-block;
    float: right;
    width: 0;
    height: 0;
    position: relative;
    border: 5px solid transparent;
    border-top: 5px solid #4a4a4a;
    margin-left: 10px;
    top: 5px;
    transform: rotate(360deg);
    transition: transform 0.3s, top 0.3s; }
    .DeptSearch-select.is-open .DeptSearch-item.is-active .DeptSearch-label::after {
      top: 2px;
      transform: rotate(180deg); }

.DeptSearch-item {
  background: white;
  cursor: pointer;
  list-style: none;
  display: none; }
  .DeptSearch-select.is-open .DeptSearch-item {
    z-index: 10;
    display: table-row; }
  .DeptSearch-item.is-active {
    display: table-header-group !important; }

.DeptSearch-radio {
  display: none; }

.DeptSearch-divider {
  padding: 10px 10px 10px 0;
  display: inline-block;
  font-size: 14px; }
  @media screen and (min-width: 800px) {
    .DeptSearch-divider {
      padding-left: 20px; } }

.DeptSearch-group {
  padding-top: 30px;
  line-height: 1.5; }
  .DeptSearch-group::after {
    clear: both;
    content: "";
    display: block; }
  .DeptSearch-group--blank {
    opacity: 0.3; }

.DeptSearch-title {
  padding: 0 7px;
  font-size: 14px;
  font-weight: bold; }

.DeptSearch-link {
  font-size: 14px;
  color: #4a4a4a;
  text-decoration: none;
  border-radius: 3px;
  padding: 0 7px;
  display: block; }
  .DeptSearch-link:hover {
    background-color: #f2f2f2; }

.DeptSearch-list {
  list-style: none;
  padding-left: 5px; }

.DeptSearch-results {
  padding-left: 0;
  list-style: none; }

.DeptSearch-nestedResults {
  padding-left: 0;
  list-style: none; }
  @media screen and (min-width: 900px) {
    .DeptSearch-nestedResults {
      column-count: 2;
      /* stylelint-disable-line plugin/no-unsupported-browser-features */ } }

.DeptSearch-items {
  padding-left: 0;
  list-style: none; }

.DeptSearch-filterGroup {
  padding-top: 15px;
  vertical-align: top; }
  @media screen and (min-width: 800px) {
    .DeptSearch-filterGroup {
      display: inline-block;
      padding: 0; } }

.DeptSearch-filter {
  min-width: 150px; }

.IntroSection {
  max-width: 800px;
  margin: 1rem auto; }

.IntroSection-text {
  max-height: 300px;
  overflow: hidden; }
  .IntroSection-text.is-initialised {
    position: relative; }
  .IntroSection-text.is-open {
    max-height: 100%; }

.IntroSection-button {
  position: absolute;
  bottom: 10px;
  width: 100%;
  text-align: center; }

.IntroSection-fade {
  background: linear-gradient(to top, #f0f0f0 0%, rgba(255, 255, 255, 0) 100%);
  height: 100px;
  bottom: 0;
  left: 0;
  width: 100%;
  position: absolute; }

.IntroSection-content > h2 {
  font-size: 14px; }

@media screen and (min-width: 500px) {
  .ContributedItem {
    display: table;
    width: 100%; } }

@media screen and (min-width: 500px) {
  .ContributedItem-info {
    display: table-cell;
    vertical-align: middle; } }

.ContributedItem-button {
  margin-top: 20px; }
  @media screen and (min-width: 500px) {
    .ContributedItem-button {
      margin-top: 0;
      display: table-cell;
      width: 190px;
      vertical-align: middle;
      text-align: center; } }

.ArrowButtons {
  display: none; }
  @supports (display: flex) {
    .ArrowButtons {
      display: block; }
      .ArrowButtons--fixed {
        position: fixed;
        padding: 0.25rem;
        background-color: #f0f0f0;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 999999;
        overflow: hidden;
        transform: translateY(-100px);
        transition: transform 0.4s;
        box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12); }
        .ArrowButtons--fixed.is-active {
          transform: translateY(47px);
          overflow: visible; } }

.ArrowButtons-list {
  padding: 0;
  list-style-type: none;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -ms-flex-pack: space-evenly;
      justify-content: space-evenly;
  max-width: 810px;
  margin: 0 auto; }

.ArrowButtons-item {
  width: 100%;
  margin: 0 0.5rem; }

.ArrowButtons-button {
  padding-right: 1rem;
  position: relative;
  color: white;
  display: block;
  text-decoration: none; }
  @media screen and (min-width: 900px) {
    .ArrowButtons-button.is-responsive {
      padding-right: 3rem; } }
  .ArrowButtons-button::after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    width: 0;
    height: 0;
    border-top: 1rem solid transparent;
    border-left: 1rem solid #aaa;
    border-bottom: 1rem solid transparent; }
  @media screen and (min-width: 900px) {
    .ArrowButtons-button.is-responsive::after {
      border-top: 3rem solid transparent;
      border-left: 3rem solid #aaa;
      border-bottom: 3rem solid transparent; } }
  .ArrowButtons-button--green.active::after {
    border-left-color: #7bb344; }
  .ArrowButtons-button--orange.active::after {
    border-left-color: #ee9f31; }
  .ArrowButtons-button--purple.active::after {
    border-left-color: #ad3c64; }
  .ArrowButtons-button--link.ArrowButtons-button--green.active:hover::after {
    border-left-color: #557c2f; }
  .ArrowButtons-button--link.ArrowButtons-button--orange.active:hover::after {
    border-left-color: #c27810; }
  .ArrowButtons-button--link.ArrowButtons-button--purple.active:hover::after {
    border-left-color: #742843; }

.ArrowButtons-box {
  height: 2rem;
  background-color: #aaa;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-direction: column;
      flex-direction: column;
  padding: 0 0.5rem; }
  @media screen and (min-width: 900px) {
    .ArrowButtons-box.is-responsive {
      height: 6rem;
      padding: 0 2rem; } }
  .ArrowButtons-button.active .ArrowButtons-box--green {
    background-color: #7bb344; }
  .ArrowButtons-button--link.active:hover .ArrowButtons-box--green {
    background-color: #557c2f; }
  .ArrowButtons-button.active .ArrowButtons-box--orange {
    background-color: #ee9f31; }
  .ArrowButtons-button--link.active:hover .ArrowButtons-box--orange {
    background-color: #c27810; }
  .ArrowButtons-button.active .ArrowButtons-box--purple {
    background-color: #ad3c64; }
  .ArrowButtons-button--link.active:hover .ArrowButtons-box--purple {
    background-color: #742843; }

.ArrowButtons-description {
  display: none; }
  @media screen and (min-width: 900px) {
    .ArrowButtons-description.is-responsive {
      line-height: 1.2;
      display: block; } }

.ArrowButtons-heading {
  font-size: 1rem;
  font-weight: bold;
  line-height: 2; }
  @media screen and (min-width: 900px) {
    .ArrowButtons-heading.is-responsive {
      font-size: 1.25rem;
      line-height: 1; } }

.DeptSectionHead {
  font-size: 18px;
  color: white;
  font-weight: bold;
  background-color: grey;
  border-radius: 15px 15px 0 0;
  padding: 10px 20px; }
  .DeptSectionHead--green {
    background-color: #7bb344; }
  .DeptSectionHead--orange {
    background-color: #ee9f31; }
  .DeptSectionHead--purple {
    background-color: #ad3c64; }

#InfraSection * td, #InfraSection * th {
  padding-bottom: 10px; }

#InfraSection * tr:hover {
  background-color: #f4f1f1; }

@media (max-width: 768px) {
  #InfraSection * .hidden-sm {
    display: none; } }

@media (max-width: 992px) {
  #InfraSection * .hidden-md {
    display: none; } }

.SectionIndicator {
  position: fixed;
  height: 100%;
  width: 1rem;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-direction: column;
      flex-direction: column;
  left: 0;
  top: 0; }
  @media screen and (min-width: 750px) {
    .SectionIndicator {
      width: 3rem; } }

.SectionIndicator-list {
  list-style: none;
  margin: 0;
  padding: 0; }

.SectionIndicator-item {
  margin: 0;
  padding: 0; }

.SectionIndicator-circle {
  display: block;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  border: 1px solid grey;
  margin-bottom: 1rem; }
  @media screen and (min-width: 750px) {
    .SectionIndicator-circle {
      width: 1rem;
      height: 1rem; } }
  .SectionIndicator-circle--green.active {
    background: #7bb344; }
  .SectionIndicator-circle--orange.active {
    background: #ee9f31; }
  .SectionIndicator-circle--purple.active {
    background: #ad3c64; }

#js-initPerformanceIndicators {
  position: relative; }

.performance-indicators-container {
  font-size: 16px;
  line-height: 18px;
  background-color: #e2e3e5 !important;
  border-radius: 20px !important;
  font-family: Lato, sans-serif !important;
  padding: 30px 30px 0px;
  box-shadow: none !important;
  margin-bottom: 30px;
  max-height: 300px;
  overflow: hidden;
  position: relative; }
  @media screen and (max-width: 400px) {
    .performance-indicators-container {
      padding: 20px 10px 10px; } }
  .performance-indicators-container .programme-name {
    font-size: 18px;
    font-weight: 800;
    line-height: 120%;
    margin-top: 0px;
    color: #3f3f3f; }
    @media screen and (max-width: 400px) {
      .performance-indicators-container .programme-name {
        padding-right: 20px;
        padding-left: 20px; } }
  .performance-indicators-container .programme-card {
    padding: 30px;
    border-radius: 20px !important; }
    @media screen and (max-width: 400px) {
      .performance-indicators-container .programme-card {
        padding: 20px; } }
    .performance-indicators-container .programme-card .indicator-type {
      font-family: Roboto, sans-serif;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.06em;
      text-transform: uppercase;
      color: #3F3F3F;
      opacity: 0.5;
      margin: 0 0 5px; }
    .performance-indicators-container .programme-card .indicator-name {
      font-weight: 700;
      font-size: 18px;
      line-height: 120%;
      color: #3F3F3F;
      margin: 0; }
    .performance-indicators-container .programme-card .quarter-selection-container {
      margin-top: 15px;
      border-bottom: 1px solid #f2f2f2;
      padding-bottom: 15px; }
      .performance-indicators-container .programme-card .quarter-selection-container .quarter-selection {
        background-color: rgba(63, 63, 63, 0.05);
        border-radius: 100px;
        padding: 9px 12px;
        box-shadow: none;
        font-weight: 700;
        font-size: 16px;
        line-height: 100%;
        color: #3F3F3F;
        margin-right: 5px;
        min-width: unset;
        text-transform: none; }
        @media screen and (max-width: 400px) {
          .performance-indicators-container .programme-card .quarter-selection-container .quarter-selection {
            padding: 9px 7px; } }
      .performance-indicators-container .programme-card .quarter-selection-container .quarter-selection.selected {
        background-color: #3F3F3F;
        color: #fff; }
      .performance-indicators-container .programme-card .quarter-selection-container .hidden-quarter {
        visibility: hidden; }
    .performance-indicators-container .programme-card .indicator-section p.section-head {
      font-family: Roboto, sans-serif;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.06em;
      text-transform: uppercase;
      color: #3f3f3f; }
      .performance-indicators-container .programme-card .indicator-section p.section-head .chart-legend {
        float: right;
        text-transform: none;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #3f3f3f; }
    .performance-indicators-container .programme-card .indicator-section p.section-text {
      border-radius: 16px;
      background-color: rgba(0, 0, 0, 0.05);
      -webkit-line-clamp: 2;
      display: -webkit-box;
      overflow: hidden;
      font-family: Roboto, sans-serif;
      font-weight: 400;
      font-size: 14px;
      line-height: 145%;
      color: #333;
      padding: 12px 12px 4px; }
    .performance-indicators-container .programme-card .indicator-section .output-text-container {
      border-radius: 16px;
      background-color: rgba(0, 0, 0, 0.05);
      display: -ms-flexbox;
      display: flex;
      width: 100%;
      min-height: 44px; }
      .performance-indicators-container .programme-card .indicator-section .output-text-container .output-text {
        width: 90%;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        overflow: hidden;
        font-family: Roboto, sans-serif;
        font-weight: 400;
        font-size: 14px;
        line-height: 145%;
        color: #333;
        padding: 12px 12px; }
        .performance-indicators-container .programme-card .indicator-section .output-text-container .output-text .data-not-available {
          opacity: 0.5; }
      .performance-indicators-container .programme-card .indicator-section .output-text-container .read-more-output {
        width: 10%;
        -ms-flex-item-align: center;
            align-self: center;
        text-align: center;
        display: none;
        cursor: pointer;
        border: none; }
        .performance-indicators-container .programme-card .indicator-section .output-text-container .read-more-output svg {
          pointer-events: none; }
    .performance-indicators-container .programme-card .indicator-section .output-chart-container {
      border-radius: 16px;
      background-color: rgba(0, 0, 0, 0.05);
      display: -ms-flexbox;
      display: flex;
      width: 100%;
      padding: 12px; }
      .performance-indicators-container .programme-card .indicator-section .output-chart-container .active-chart {
        border: 1px solid #3f3f3f;
        border-radius: 6px; }
      .performance-indicators-container .programme-card .indicator-section .output-chart-container .bar-text {
        text-align: center;
        font-size: 12px; }
      .performance-indicators-container .programme-card .indicator-section .output-chart-container .unavailable-chart-indicator {
        height: 100%;
        min-height: 100px;
        -ms-flex-align: center;
            align-items: center;
        display: -ms-flexbox;
        display: flex; }
        @media screen and (max-width: 400px) {
          .performance-indicators-container .programme-card .indicator-section .output-chart-container .unavailable-chart-indicator {
            min-height: 50px; } }
        .performance-indicators-container .programme-card .indicator-section .output-chart-container .unavailable-chart-indicator svg {
          margin: 0 auto; }
    .performance-indicators-container .programme-card .indicator-section .output-text-container.read-more-visible .read-more-output {
      display: block; }
    .performance-indicators-container .programme-card .indicator-section .output-text-container.read-more-visible .output-text {
      padding: 12px 12px 4px; }
  .performance-indicators-container .float-right {
    float: right; }
  .performance-indicators-container .IntroSection-fade {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.34) 100%); }
  .performance-indicators-container .IntroSection-button {
    bottom: 40px; }
    .performance-indicators-container .IntroSection-button button {
      border: none; }

.performance-indicators-container.is-open {
  max-height: unset;
  padding-bottom: 30px; }

.programme-btn {
  text-transform: none !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 100% !important;
  font-family: Lato, sans-serif !important;
  border-radius: 100px !important;
  border: 1px solid #3f3f3f !important;
  background-color: #fff !important;
  padding: 9px 12px !important;
  letter-spacing: unset !important;
  font-stretch: normal !important; }

.MuiTooltip-tooltip {
  font-family: Roboto, sans-serif !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 145% !important;
  color: #333 !important;
  background-color: #fff !important;
  border-radius: 20px !important;
  white-space: nowrap;
  max-width: unset !important; }

@media screen and (max-width: 400px) {
  .Page-contentInner--department {
    padding-right: 1rem;
    padding-left: 1rem; } }

.PublicEntityControls::after {
  clear: both;
  content: "";
  display: block; }

.PublicEntityControls-keywords {
  width: 100%;
  max-width: 600px; }
  @media screen and (min-width: 1000px) {
    .PublicEntityControls-keywords {
      width: 350px;
      float: left; } }

.PublicEntityControls-in {
  float: left;
  padding: 10px;
  font-size: 14px; }

.PublicEntityControls-dropdown {
  float: left;
  width: 230px; }

.PublicEntityControls-itemWrap {
  padding: 10px 0; }
  .PublicEntityControls-itemWrap::after {
    clear: both;
    content: "";
    display: block; }
  @media screen and (min-width: 1000px) {
    .PublicEntityControls-itemWrap {
      float: left;
      padding: 0; } }

.DepartmentPublicEntity-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2; }
  .DepartmentPublicEntity-list li {
    list-style-type: none; }

.PublicEntityGroup {
  border-radius: 20px;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.25);
  background-color: white;
  padding: 23px 21px;
  position: relative;
  overflow: hidden; }

.PublicEntityGroup-title {
  font-weight: bold;
  font-weight: 900;
  font-size: 18px;
  padding-bottom: 23px;
  margin: 0; }

.PublicEntityGroup-item {
  font-size: 14px; }

.PublicEntityGroup-mapWrap {
  position: relative; }

.PublicEntityGroup-map {
  position: absolute;
  z-index: 5;
  right: 0;
  top: 0;
  height: 100%;
  width: 100%;
  text-align: right;
  padding: 5px;
  fill: #f5f5f5; }

.PublicEntityGroup-svg {
  height: 100%;
  width: auto; }

.PublicEntityGroup-list {
  list-style: none;
  padding-left: 0;
  margin: 0; }
  @media screen and (min-width: 750px) {
    .PublicEntityGroup-list--doubleRow {
      column-count: 2; } }

.PublicEntitySearch-table {
  width: 100%;
  border-collapse: collapse; }
  .PublicEntitySearch-table thead th {
    text-align: left;
    padding: 10px 6px;
    border-bottom: 1px solid #f2f2f2; }
  .PublicEntitySearch-table tbody tr td {
    padding: 10px 6px; }
  .PublicEntitySearch-table tbody tr:hover td {
    background-color: #f5f5f5; }

.PublicEntityGroup-wrap {
  position: relative;
  z-index: 10; }

.PublicEntityGroup-link {
  text-decoration: underline;
  color: #3f3f3f; }
  .PublicEntityGroup-link:hover {
    color: #1f1f1f; }

.PublicEntityGroup-expenditure-outer {
  background-color: #f5f5f5;
  border-radius: 5px; }
  .PublicEntityGroup-expenditure-outer .PublicEntityGroup-expenditure-inner {
    background-color: #79b43c;
    padding: 4px 6px;
    font-size: smaller;
    border-radius: 5px; }

.PublicEntitySearch-keywords {
  border: solid 1px #9b9b9b;
  border-radius: 3px;
  padding: 9px 13px;
  font-size: 14px;
  width: 100%;
  max-width: 300px;
  display: inline-block; }
  .PublicEntitySearch-keywords.is-loading {
    height: 36px; }

.PublicEntitySearch-groupWrap {
  padding-bottom: 20px; }

.PublicEntitySearch-select {
  border-radius: 3px;
  border: solid 1px #9b9b9b;
  padding-left: 0;
  display: table;
  min-width: 170px;
  margin: 0;
  background-color: white; }

.PublicEntitySearch-filter {
  display: inline-block;
  vertical-align: top;
  max-height: 36px; }

.PublicEntitySearch-label {
  cursor: pointer;
  padding: 9px 13px;
  font-size: 14px;
  display: block;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  .PublicEntitySearch-label:hover {
    background-color: #d9d9d9; }
  .PublicEntitySearch-select.is-open .PublicEntitySearch-item.is-active .PublicEntitySearch-label {
    border-bottom: 1px solid #4a4a4a; }
  .PublicEntitySearch-item.is-active .PublicEntitySearch-label::after {
    content: '';
    display: inline-block;
    float: right;
    width: 0;
    height: 0;
    position: relative;
    border: 5px solid transparent;
    border-top: 5px solid #4a4a4a;
    margin-left: 10px;
    top: 5px;
    transform: rotate(360deg);
    transition: transform 0.3s, top 0.3s; }
    .PublicEntitySearch-select.is-open .PublicEntitySearch-item.is-active .PublicEntitySearch-label::after {
      top: 2px;
      transform: rotate(180deg); }

.PublicEntitySearch-item {
  background: white;
  cursor: pointer;
  list-style: none;
  display: none; }
  .PublicEntitySearch-select.is-open .PublicEntitySearch-item {
    z-index: 10;
    display: table-row; }
  .PublicEntitySearch-item.is-active {
    display: table-header-group !important; }

.PublicEntitySearch-radio {
  display: none; }

.PublicEntitySearch-divider {
  padding: 10px 10px 10px 0;
  display: inline-block;
  font-size: 14px; }
  @media screen and (min-width: 800px) {
    .PublicEntitySearch-divider {
      padding-left: 20px; } }

.PublicEntitySearch-group {
  padding-top: 30px;
  line-height: 1.5; }
  .PublicEntitySearch-group::after {
    clear: both;
    content: "";
    display: block; }
  .PublicEntitySearch-group--blank {
    opacity: 0.3; }

.PublicEntitySearch-title {
  padding: 0 7px;
  font-size: 14px;
  font-weight: bold; }

.PublicEntitySearch-link {
  font-size: 14px;
  color: #4a4a4a;
  text-decoration: none;
  border-radius: 3px;
  padding: 0 7px;
  display: block; }
  .PublicEntitySearch-link:hover {
    background-color: #f2f2f2; }

.PublicEntitySearch-list {
  list-style: none;
  padding-left: 5px; }

.PublicEntitySearch-results {
  padding-left: 0;
  list-style: none; }

.PublicEntitySearch-nestedResults {
  padding-left: 0;
  list-style: none; }
  @media screen and (min-width: 900px) {
    .PublicEntitySearch-nestedResults {
      column-count: 2;
      /* stylelint-disable-line plugin/no-unsupported-browser-features */ } }

.PublicEntitySearch-items {
  padding-left: 0;
  list-style: none; }

.PublicEntitySearch-filterGroup {
  padding-top: 15px;
  vertical-align: top; }
  @media screen and (min-width: 800px) {
    .PublicEntitySearch-filterGroup {
      display: inline-block;
      padding: 0; } }

.PublicEntitySearch-filter {
  min-width: 150px; }

.IntroSection {
  max-width: 800px;
  margin: 1rem auto; }

.IntroSection-text {
  max-height: 300px;
  overflow: hidden; }
  .IntroSection-text.is-initialised {
    position: relative; }
  .IntroSection-text.is-open {
    max-height: 100%; }

.IntroSection-button {
  position: absolute;
  bottom: 10px;
  width: 100%;
  text-align: center; }

.IntroSection-fade {
  background: linear-gradient(to top, #f0f0f0 0%, rgba(255, 255, 255, 0) 100%);
  height: 100px;
  bottom: 0;
  left: 0;
  width: 100%;
  position: absolute; }

.IntroSection-content > h2 {
  font-size: 14px; }

@media screen and (min-width: 500px) {
  .ContributedItem {
    display: table;
    width: 100%; } }

@media screen and (min-width: 500px) {
  .ContributedItem-info {
    display: table-cell;
    vertical-align: middle; } }

.ContributedItem-button {
  margin-top: 20px; }
  @media screen and (min-width: 500px) {
    .ContributedItem-button {
      margin-top: 0;
      display: table-cell;
      width: 190px;
      vertical-align: middle;
      text-align: center; } }

.ArrowButtons {
  display: none; }
  @supports (display: flex) {
    .ArrowButtons {
      display: block; }
      .ArrowButtons--fixed {
        position: fixed;
        padding: 0.25rem;
        background-color: #f0f0f0;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 999999;
        overflow: hidden;
        transform: translateY(-100px);
        transition: transform 0.4s;
        box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12); }
        .ArrowButtons--fixed.is-active {
          transform: translateY(47px);
          overflow: visible; } }

.ArrowButtons-list {
  padding: 0;
  list-style-type: none;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -ms-flex-pack: space-evenly;
      justify-content: space-evenly;
  max-width: 810px;
  margin: 0 auto; }

.ArrowButtons-item {
  width: 100%;
  margin: 0 0.5rem; }

.ArrowButtons-button {
  padding-right: 1rem;
  position: relative;
  color: white;
  display: block;
  text-decoration: none; }
  @media screen and (min-width: 900px) {
    .ArrowButtons-button.is-responsive {
      padding-right: 3rem; } }
  .ArrowButtons-button::after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    width: 0;
    height: 0;
    border-top: 1rem solid transparent;
    border-left: 1rem solid #aaa;
    border-bottom: 1rem solid transparent; }
  @media screen and (min-width: 900px) {
    .ArrowButtons-button.is-responsive::after {
      border-top: 3rem solid transparent;
      border-left: 3rem solid #aaa;
      border-bottom: 3rem solid transparent; } }
  .ArrowButtons-button--green.active::after {
    border-left-color: #7bb344; }
  .ArrowButtons-button--orange.active::after {
    border-left-color: #ee9f31; }
  .ArrowButtons-button--purple.active::after {
    border-left-color: #ad3c64; }
  .ArrowButtons-button--link.ArrowButtons-button--green.active:hover::after {
    border-left-color: #557c2f; }
  .ArrowButtons-button--link.ArrowButtons-button--orange.active:hover::after {
    border-left-color: #c27810; }
  .ArrowButtons-button--link.ArrowButtons-button--purple.active:hover::after {
    border-left-color: #742843; }

.ArrowButtons-box {
  height: 2rem;
  background-color: #aaa;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-direction: column;
      flex-direction: column;
  padding: 0 0.5rem; }
  @media screen and (min-width: 900px) {
    .ArrowButtons-box.is-responsive {
      height: 6rem;
      padding: 0 2rem; } }
  .ArrowButtons-button.active .ArrowButtons-box--green {
    background-color: #7bb344; }
  .ArrowButtons-button--link.active:hover .ArrowButtons-box--green {
    background-color: #557c2f; }
  .ArrowButtons-button.active .ArrowButtons-box--orange {
    background-color: #ee9f31; }
  .ArrowButtons-button--link.active:hover .ArrowButtons-box--orange {
    background-color: #c27810; }
  .ArrowButtons-button.active .ArrowButtons-box--purple {
    background-color: #ad3c64; }
  .ArrowButtons-button--link.active:hover .ArrowButtons-box--purple {
    background-color: #742843; }

.ArrowButtons-description {
  display: none; }
  @media screen and (min-width: 900px) {
    .ArrowButtons-description.is-responsive {
      line-height: 1.2;
      display: block; } }

.ArrowButtons-heading {
  font-size: 1rem;
  font-weight: bold;
  line-height: 2; }
  @media screen and (min-width: 900px) {
    .ArrowButtons-heading.is-responsive {
      font-size: 1.25rem;
      line-height: 1; } }

.PublicEntitySectionHead {
  font-size: 18px;
  color: white;
  font-weight: bold;
  background-color: grey;
  border-radius: 15px 15px 0 0;
  padding: 10px 20px; }
  .PublicEntitySectionHead--green {
    background-color: #7bb344; }
  .PublicEntitySectionHead--orange {
    background-color: #ee9f31; }
  .PublicEntitySectionHead--purple {
    background-color: #ad3c64; }

#InfraSection * td, #InfraSection * th {
  padding-bottom: 10px; }

#InfraSection * tr:hover {
  background-color: #f4f1f1; }

@media (max-width: 768px) {
  #InfraSection * .hidden-sm {
    display: none; } }

@media (max-width: 992px) {
  #InfraSection * .hidden-md {
    display: none; } }

.SectionIndicator {
  position: fixed;
  height: 100%;
  width: 1rem;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-direction: column;
      flex-direction: column;
  left: 0;
  top: 0; }
  @media screen and (min-width: 750px) {
    .SectionIndicator {
      width: 3rem; } }

.SectionIndicator-list {
  list-style: none;
  margin: 0;
  padding: 0; }

.SectionIndicator-item {
  margin: 0;
  padding: 0; }

.SectionIndicator-circle {
  display: block;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  border: 1px solid grey;
  margin-bottom: 1rem; }
  @media screen and (min-width: 750px) {
    .SectionIndicator-circle {
      width: 1rem;
      height: 1rem; } }
  .SectionIndicator-circle--green.active {
    background: #7bb344; }
  .SectionIndicator-circle--orange.active {
    background: #ee9f31; }
  .SectionIndicator-circle--purple.active {
    background: #ad3c64; }

#js-initPerformanceIndicators {
  position: relative; }

.performance-indicators-container {
  font-size: 16px;
  line-height: 18px;
  background-color: #e2e3e5 !important;
  border-radius: 20px !important;
  font-family: Lato, sans-serif !important;
  padding: 30px 30px 0px;
  box-shadow: none !important;
  margin-bottom: 30px;
  max-height: 300px;
  overflow: hidden;
  position: relative; }
  @media screen and (max-width: 400px) {
    .performance-indicators-container {
      padding: 20px 10px 10px; } }
  .performance-indicators-container .programme-name {
    font-size: 18px;
    font-weight: 800;
    line-height: 120%;
    margin-top: 0px;
    color: #3f3f3f; }
    @media screen and (max-width: 400px) {
      .performance-indicators-container .programme-name {
        padding-right: 20px;
        padding-left: 20px; } }
  .performance-indicators-container .programme-card {
    padding: 30px;
    border-radius: 20px !important; }
    @media screen and (max-width: 400px) {
      .performance-indicators-container .programme-card {
        padding: 20px; } }
    .performance-indicators-container .programme-card .indicator-type {
      font-family: Roboto, sans-serif;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.06em;
      text-transform: uppercase;
      color: #3F3F3F;
      opacity: 0.5;
      margin: 0 0 5px; }
    .performance-indicators-container .programme-card .indicator-name {
      font-weight: 700;
      font-size: 18px;
      line-height: 120%;
      color: #3F3F3F;
      margin: 0; }
    .performance-indicators-container .programme-card .quarter-selection-container {
      margin-top: 15px;
      border-bottom: 1px solid #f2f2f2;
      padding-bottom: 15px; }
      .performance-indicators-container .programme-card .quarter-selection-container .quarter-selection {
        background-color: rgba(63, 63, 63, 0.05);
        border-radius: 100px;
        padding: 9px 12px;
        box-shadow: none;
        font-weight: 700;
        font-size: 16px;
        line-height: 100%;
        color: #3F3F3F;
        margin-right: 5px;
        min-width: unset;
        text-transform: none; }
        @media screen and (max-width: 400px) {
          .performance-indicators-container .programme-card .quarter-selection-container .quarter-selection {
            padding: 9px 7px; } }
      .performance-indicators-container .programme-card .quarter-selection-container .quarter-selection.selected {
        background-color: #3F3F3F;
        color: #fff; }
      .performance-indicators-container .programme-card .quarter-selection-container .hidden-quarter {
        visibility: hidden; }
    .performance-indicators-container .programme-card .indicator-section p.section-head {
      font-family: Roboto, sans-serif;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.06em;
      text-transform: uppercase;
      color: #3f3f3f; }
      .performance-indicators-container .programme-card .indicator-section p.section-head .chart-legend {
        float: right;
        text-transform: none;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #3f3f3f; }
    .performance-indicators-container .programme-card .indicator-section p.section-text {
      border-radius: 16px;
      background-color: rgba(0, 0, 0, 0.05);
      -webkit-line-clamp: 2;
      display: -webkit-box;
      overflow: hidden;
      font-family: Roboto, sans-serif;
      font-weight: 400;
      font-size: 14px;
      line-height: 145%;
      color: #333;
      padding: 12px 12px 4px; }
    .performance-indicators-container .programme-card .indicator-section .output-text-container {
      border-radius: 16px;
      background-color: rgba(0, 0, 0, 0.05);
      display: -ms-flexbox;
      display: flex;
      width: 100%;
      min-height: 44px; }
      .performance-indicators-container .programme-card .indicator-section .output-text-container .output-text {
        width: 90%;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        overflow: hidden;
        font-family: Roboto, sans-serif;
        font-weight: 400;
        font-size: 14px;
        line-height: 145%;
        color: #333;
        padding: 12px 12px; }
        .performance-indicators-container .programme-card .indicator-section .output-text-container .output-text .data-not-available {
          opacity: 0.5; }
      .performance-indicators-container .programme-card .indicator-section .output-text-container .read-more-output {
        width: 10%;
        -ms-flex-item-align: center;
            align-self: center;
        text-align: center;
        display: none;
        cursor: pointer;
        border: none; }
        .performance-indicators-container .programme-card .indicator-section .output-text-container .read-more-output svg {
          pointer-events: none; }
    .performance-indicators-container .programme-card .indicator-section .output-chart-container {
      border-radius: 16px;
      background-color: rgba(0, 0, 0, 0.05);
      display: -ms-flexbox;
      display: flex;
      width: 100%;
      padding: 12px; }
      .performance-indicators-container .programme-card .indicator-section .output-chart-container .active-chart {
        border: 1px solid #3f3f3f;
        border-radius: 6px; }
      .performance-indicators-container .programme-card .indicator-section .output-chart-container .bar-text {
        text-align: center;
        font-size: 12px; }
      .performance-indicators-container .programme-card .indicator-section .output-chart-container .unavailable-chart-indicator {
        height: 100%;
        min-height: 100px;
        -ms-flex-align: center;
            align-items: center;
        display: -ms-flexbox;
        display: flex; }
        @media screen and (max-width: 400px) {
          .performance-indicators-container .programme-card .indicator-section .output-chart-container .unavailable-chart-indicator {
            min-height: 50px; } }
        .performance-indicators-container .programme-card .indicator-section .output-chart-container .unavailable-chart-indicator svg {
          margin: 0 auto; }
    .performance-indicators-container .programme-card .indicator-section .output-text-container.read-more-visible .read-more-output {
      display: block; }
    .performance-indicators-container .programme-card .indicator-section .output-text-container.read-more-visible .output-text {
      padding: 12px 12px 4px; }
  .performance-indicators-container .float-right {
    float: right; }
  .performance-indicators-container .IntroSection-fade {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.34) 100%); }
  .performance-indicators-container .IntroSection-button {
    bottom: 40px; }
    .performance-indicators-container .IntroSection-button button {
      border: none; }

.performance-indicators-container.is-open {
  max-height: unset;
  padding-bottom: 30px; }

.programme-btn {
  text-transform: none !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 100% !important;
  font-family: Lato, sans-serif !important;
  border-radius: 100px !important;
  border: 1px solid #3f3f3f !important;
  background-color: #fff !important;
  padding: 9px 12px !important;
  letter-spacing: unset !important;
  font-stretch: normal !important; }

.MuiTooltip-tooltip {
  font-family: Roboto, sans-serif !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 145% !important;
  color: #333 !important;
  background-color: #fff !important;
  border-radius: 20px !important;
  white-space: nowrap;
  max-width: unset !important; }

@media screen and (max-width: 400px) {
  .Page-contentInner--public-entity {
    padding-right: 1rem;
    padding-left: 1rem; } }

.CsoMeta {
  width: 100%;
  list-style: none;
  margin: 0;
  display: block;
  padding: 20px 0; }
  @media screen and (min-width: 750px) {
    .CsoMeta {
      display: table; } }

.CsoMeta-title {
  font-size: 18px;
  line-height: 1.44;
  margin: 0 0 4px;
  font-weight: bold;
  font-weight: 900; }

.CsoMeta-orgTitle {
  display: block;
  font-weight: normal;
  max-width: 240px; }

.CsoMeta-container {
  margin-top: 10px; }
  @media screen and (min-width: 750px) {
    .CsoMeta-container {
      margin-top: 0;
      display: table-cell; } }

.CsoMeta-dataSetWrap {
  border-radius: 20px;
  background-color: #e6e6e6;
  border: solid 1px #d2d2d2;
  padding: 10px; }

.CsoMeta-dataSet {
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.25);
  border: solid 1px #d2d2d2;
  padding: 11px 20px 32px;
  display: block; }
  .CsoMeta-dataSet::after {
    clear: both;
    content: "";
    display: block; }

.CsoMeta-column {
  padding-right: 20px;
  float: left;
  width: 100%; }
  @media screen and (min-width: 950px) {
    .CsoMeta-column {
      width: 50%; } }

.CsoMeta-organisation {
  display: block;
  padding-left: 20px; }
  @media screen and (min-width: 750px) {
    .CsoMeta-organisation {
      display: table-cell;
      width: 300px; } }

.CsoMeta-logo {
  max-width: 240px;
  height: 100px;
  width: 100%;
  background-position: 0 center;
  background-size: contain;
  background-repeat: no-repeat; }

.CsoMeta-line {
  padding: 2px 0;
  word-break: break-all; }
  .CsoMeta-line--link {
    color: #4a4a4a;
    border-radius: 6px;
    padding: 0 6px;
    position: relative;
    left: -6px; }
    .CsoMeta-line--link:hover {
      background: #f2f2f2; }

.CsoMeta-listItems {
  margin: 0; }
  .CsoMeta-listItems::after {
    clear: both;
    content: "";
    display: block; }

.CsoMeta-listItem {
  float: left;
  width: 100%;
  margin: 0; }
  .CsoMeta-listItem.is-title {
    font-weight: bold;
    font-weight: 900;
    padding-top: 14px; }
  .CsoMeta-listItem--icon {
    padding-top: 7px;
    display: inline-block;
    width: auto; }

.CsoMeta-item {
  display: inline-block; }
  .CsoMeta-item--link {
    color: #4a4a4a;
    border-radius: 6px;
    padding: 0 6px;
    position: relative;
    left: -6px; }
    .CsoMeta-item--link:hover {
      background: #e3e3e3; }

.CsoMeta-list::after {
  clear: both;
  content: "";
  display: block; }

.Screenshots {
  padding-bottom: 30px; }
  @media screen and (min-width: 700px) {
    .Screenshots {
      padding: 30px;
      float: right; } }

.Screenshots-imageWrap {
  border: 1px solid #ccc;
  display: block;
  max-width: 400px;
  margin-bottom: 15px; }
  .Screenshots-imageWrap:nth-child(n+2) {
    display: none; }
  @media screen and (min-width: 700px) {
    .Screenshots-imageWrap {
      max-width: 200px; }
      .Screenshots-imageWrap:nth-child(n+2) {
        display: block; } }

.Screenshots-image {
  width: 100%; }

.Glossary {
  padding: 15px 0; }

.Glossary-search {
  border: 1px solid #7d7d7d;
  border-radius: 3px;
  padding: 9px 13px;
  font-size: 14px;
  width: 100%;
  max-width: 300px;
  display: inline-block;
  margin-bottom: 20px; }

.Glossary-letter {
  opacity: 0.2;
  display: inline-block;
  padding: 5px 7px;
  font-size: 14px;
  text-transform: uppercase;
  text-decoration: none;
  border-radius: 3px;
  color: #3f3f3f; }
  .Glossary-letter.is-valid {
    cursor: pointer;
    opacity: 1; }
    .Glossary-letter.is-valid:hover {
      background-color: #e6e6e6; }

.Glossary-heading {
  font-size: 18px;
  font-weight: bold;
  border-bottom: solid 1px #7d7d7d;
  padding-bottom: 5px; }

.Glossary-title {
  font-weight: bold; }

.Glossary-item {
  margin: 20px 0;
  line-height: 1.3; }

.Glossary-section {
  margin-top: 20px; }

.Glossary-text {
  white-space: pre-wrap; }

.SearchResult {
  min-height: 1000px; }

.SearchResult-tabWrap {
  position: relative;
  font-weight: bold;
  width: 100%;
  text-decoration: underline;
  margin-bottom: 20px; }
  .SearchResult-tabWrap::after {
    position: absolute;
    top: 100%;
    top: calc(100% + 2px);
    content: '';
    display: block;
    width: 100%;
    left: 0;
    border-bottom: 2px solid #cfcfcf;
    height: 4px; }

.SearchResult-tabList {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around; }

.SearchResult-tabItem {
  font-size: 14px;
  position: relative;
  z-index: 99999;
  padding: 7px 15px;
  border-radius: 50px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background: none;
  border-width: 0;
  outline-width: 0; }
  .SearchResult-tabItem::after {
    position: absolute;
    top: 100%;
    top: calc(100% + 2px);
    left: 0;
    content: '';
    display: block;
    width: 100%;
    background: #7bb344;
    border-radius: 15px;
    height: 10px;
    opacity: 0; }
  .SearchResult-tabItem:hover {
    cursor: pointer;
    background: #c2c2c2; }
  .SearchResult-tabItem.is-active::after {
    opacity: 1; }

.SearchResult-heading {
  padding-bottom: 10px; }
  .SearchResult-heading::after {
    clear: both;
    content: "";
    display: block; }

.SearchResult-leftHeading {
  float: left; }

.SearchResult-rightHeading {
  float: right;
  display: none; }
  @media screen and (min-width: 750px) {
    .SearchResult-rightHeading {
      display: block; } }

.SearchResult-buttons::after {
  clear: both;
  content: "";
  display: block; }

.SearchResult-buttonsTitle {
  float: left;
  margin-right: 20px;
  margin-top: 10px;
  width: 100%;
  text-align: center; }
  @media screen and (min-width: 500px) {
    .SearchResult-buttonsTitle {
      text-align: left; } }

.SearchResult-buttonItem {
  float: left;
  text-align: center;
  margin-right: 10px;
  margin-bottom: 10px;
  width: 100%; }
  @media screen and (min-width: 500px) {
    .SearchResult-buttonItem {
      text-align: left;
      width: auto; } }

.SearchResult-icon {
  fill: white;
  width: 25px;
  height: 25px;
  position: relative;
  top: 16px;
  left: 4px; }

.SearchResult-iconWrap {
  width: 60px;
  height: 60px;
  background: grey;
  top: 50%;
  top: calc(50% - 30px);
  left: 50%;
  left: calc(50% - 30px);
  position: absolute;
  border-radius: 50%;
  text-align: center; }
  .SearchResult-iconWrap::before {
    content: '';
    display: block;
    width: 60px;
    height: 60px;
    top: 50%;
    top: calc(50% - 32px);
    left: 50%;
    left: calc(50% - 32px);
    position: absolute;
    border-radius: 50%;
    text-align: center;
    border: 2px solid grey;
    opacity: 0.8;
    transition: transform 0.4s, opacity 0.3s; }
  .SearchResult-thumbnailWrap:hover .SearchResult-iconWrap::before {
    transform: scale(1.2);
    opacity: 0.4; }

.SearchResult-thumbnailWrap {
  display: block;
  width: 100%;
  max-width: 245.99px;
  vertical-align: top;
  background: black;
  line-height: 0;
  position: relative;
  border-radius: 15px;
  overflow: hidden;
  margin: 0 auto; }
  .SearchResult-thumbnailWrap:hover::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.1);
    display: block; }

.SearchResult-thumbnail {
  width: 100%; }

.SearchResult-card {
  background-color: #fff;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  padding: 2rem;
  margin: 2px;
  line-height: 1.45; }
  .SearchResult-card.is-dark {
    background-color: #e6e6e6; }

.SearchResult-cardTitle {
  font-weight: 900;
  line-height: normal;
  font-size: 18px;
  margin-bottom: 1rem;
  display: block; }

.Contacts-icon {
  vertical-align: top;
  display: block;
  background: #3f3f3f;
  width: 60px;
  height: 60px;
  text-align: center;
  border-radius: 50%;
  overflow: hidden;
  border: 5px solid #3f3f3f; }

.Contacts-iconWrap {
  display: table-cell; }

.Contacts-svg {
  position: relative;
  top: 3px; }

.Contacts-title {
  font-size: 14px;
  font-weight: bold;
  line-height: 1.57;
  padding: 25px 0; }

.Contacts-info {
  vertical-align: top;
  display: table-cell;
  padding-left: 20px;
  list-style: none; }

.Contacts-item {
  display: table;
  padding-bottom: 40px; }

.Contacts-line {
  font-size: 14px;
  line-height: 1.45; }
  .Contacts-line.is-title {
    font-weight: bold; }

.Contacts-highlight:target {
  color: white;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  left: -20px;
  top: -20px;
  animation-name: background-pulse;
  animation-duration: 3s;
  animation-iteration-count: 2;
  animation-fill-mode: forwards; }
  .Contacts-highlight:target a {
    color: white; }

.Progress {
  padding-bottom: 25px; }
  @media screen and (min-width: 900px) {
    .Progress {
      display: -ms-flexbox;
      display: flex; } }

.Progress-side {
  text-align: center;
  -ms-flex-order: 1;
      order: 1;
  margin-left: 25px;
  padding-bottom: 25px; }
  @media screen and (min-width: 900px) {
    .Progress-side {
      padding-bottom: 0; } }

.Progress-wrap {
  padding-left: 0;
  list-style: none;
  margin: 0 auto;
  display: inline-block; }
  @media screen and (min-width: 300px) {
    .Progress-wrap {
      display: block;
      max-width: 300px; } }
  @media screen and (min-width: 600px) {
    .Progress-wrap {
      display: block;
      max-width: 1024px; } }

.Progress-pin {
  vertical-align: top;
  margin: 7px;
  font-weight: bold;
  text-align: center;
  background: #d2d2d2;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  padding: 5px;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  position: relative;
  color: white; }
  @media screen and (min-width: 900px) {
    .Progress-pin {
      margin: 0 auto;
      display: -ms-flexbox;
      display: flex;
      margin-bottom: 25px; } }
  .Progress-pin.is-active {
    background: #5793f3; }
    @media screen and (min-width: 900px) {
      .Progress-pin.is-active {
        margin-bottom: 46px;
        width: 170px;
        height: 170px;
        font-size: 18px; } }
  .Progress-pin.is-finished {
    background: #5793f3; }
  .Progress-pin::after {
    content: '';
    display: block;
    position: absolute;
    background: #d2d2d2;
    height: 38px;
    width: 38px;
    border-radius: 2px;
    z-index: -1;
    top: 50px;
    left: 21px;
    transform: rotate(45deg); }
  .Progress-pin.is-active::after {
    background: #444; }
    @media screen and (min-width: 900px) {
      .Progress-pin.is-active::after {
        top: 97px;
        width: 87px;
        height: 90px;
        left: 42px;
        background: #444; } }

.Progress-icon {
  fill: white;
  position: absolute;
  width: 25px;
  height: 25px;
  right: 0;
  top: 0;
  border-radius: 50%;
  padding: 6px;
  background-color: #ad3c64; }

.Progress-seperator {
  padding-top: 25px; }

.Stages {
  padding-left: 0;
  list-style: none; }
  @media screen and (min-width: 900px) {
    .Stages {
      display: table;
      padding-bottom: 25px; } }

@media screen and (min-width: 900px) {
  .Stages-item {
    display: table-row; } }

@media screen and (min-width: 900px) {
  .Stages-pinWrap {
    display: table-cell;
    width: 100px; } }

@media screen and (min-width: 900px) {
  .Stages-descriptionWrap {
    display: table-cell; } }

.Stages-pin {
  line-height: 1;
  position: relative;
  margin: 40px 0 0 20px;
  color: white;
  font-size: 17px; }
  @media screen and (min-width: 900px) {
    .Stages-pin {
      padding: 20px 15px;
      font-weight: bold;
      text-align: center;
      background: #f0f0f0;
      border-radius: 50%;
      width: 100px;
      height: 100px;
      z-index: 0;
      margin-bottom: 25px;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: center;
          justify-content: center;
      -ms-flex-align: center;
          align-items: center; }
      .Stages-item:nth-of-type(1) .Stages-pin {
        background-color: #7bb344; }
      .Stages-item:nth-of-type(2) .Stages-pin {
        background-color: #ee9f31; }
      .Stages-item:nth-of-type(3) .Stages-pin {
        background-color: #c66347; }
      .Stages-item:nth-of-type(4) .Stages-pin {
        background-color: #ad3c64; }
      .Stages-item:nth-of-type(5) .Stages-pin {
        background-color: #5793f3; } }
  .Stages-pin::after {
    width: 10px;
    height: 10px;
    content: '';
    left: -20px;
    top: 1px;
    display: block;
    position: absolute;
    border-radius: 50%;
    background-color: #f0f0f0; }
    .Stages-item:nth-of-type(1) .Stages-pin::after {
      background-color: #7bb344; }
    .Stages-item:nth-of-type(2) .Stages-pin::after {
      background-color: #ee9f31; }
    .Stages-item:nth-of-type(3) .Stages-pin::after {
      background-color: #c66347; }
    .Stages-item:nth-of-type(4) .Stages-pin::after {
      background-color: #ad3c64; }
    .Stages-item:nth-of-type(5) .Stages-pin::after {
      background-color: #5793f3; }
    @media screen and (min-width: 900px) {
      .Stages-pin::after {
        border-radius: 2px;
        height: 58px;
        width: 58px;
        top: 50px;
        left: 21px;
        transform: rotate(45deg);
        z-index: -1; } }

@media screen and (min-width: 900px) {
  .Stages-descriptionWrap {
    vertical-align: middle; } }

.Stages-description {
  font-size: 14px;
  line-height: 1.57; }
  @media screen and (min-width: 900px) {
    .Stages-description {
      padding: 16px;
      padding-left: 40px; } }

.DescriptionEmbedSection-container {
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -ms-flex-direction: column;
      flex-direction: column; }
  @media screen and (min-width: 1000px) {
    .DescriptionEmbedSection-container {
      -ms-flex-flow: row wrap;
          flex-flow: row wrap; } }

.DescriptionEmbedSection-item {
  min-width: 0;
  padding-bottom: 1rem; }
  @media screen and (min-width: 1000px) {
    .DescriptionEmbedSection-item {
      width: 33%;
      padding-right: 1rem; }
      .DescriptionEmbedSection-item--right {
        width: 66%; } }
  @media screen and (max-width: 1000px) {
    .DescriptionEmbedSection-item--right {
      width: calc(100% + 3rem);
      margin-left: -1.5rem; } }
  .DescriptionEmbedSection-item--right .Card {
    height: calc(100vh - 145px); }
    .DescriptionEmbedSection-item--right .Card iframe {
      width: 100%;
      height: 100%;
      border: 0px; }

.DescriptionEmbedSection-share {
  max-width: 250px; }

.SiteNotice {
  border-bottom: 1px solid black;
  background: #fff; }
  .SiteNotice p {
    margin: 0px;
    padding: 5px 15px; }

.budget-documents {
  z-index: 10;
  position: relative; }
  .budget-documents .cards-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-gap: 10px; }
  .budget-documents .item {
    display: inline-block; }
    .budget-documents .item .title {
      margin-bottom: 5px;
      font-size: 15px;
      font-weight: 400; }
    .budget-documents .item .format {
      font-size: 10px;
      font-weight: 400;
      margin-bottom: 10px; }
    .budget-documents .item .title.skeleton {
      height: 12px;
      width: 200px;
      background-color: #e8dada;
      border-radius: 3px; }
    .budget-documents .item .Button .label.skeleton {
      display: inline-block;
      width: 100px;
      height: 12px;
      background-color: #e8dada;
      border-radius: 3px; }
    .budget-documents .item .Button.skeleton {
      border-color: #e8dada; }
    .budget-documents .item a:link.resource-link {
      text-decoration: none; }
  .budget-documents.collapsed {
    height: 200px;
    overflow: hidden; }
  .budget-documents .expand-shim {
    position: absolute;
    bottom: 0px;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), white);
    width: 100%;
    height: 200px; }
    .budget-documents .expand-shim .Button {
      position: absolute;
      width: 200px;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto; }

@media only screen and (min-width: 500px) {
  .budget-documents .cards-container {
    grid-template-columns: 1fr 1fr; } }

@media only screen and (min-width: 850px) {
  .budget-documents .cards-container {
    grid-template-columns: 1fr 1fr 1fr; } }

.performance-table-paper {
  padding: 20px;
  border-radius: 20px !important;
  position: relative;
  height: calc(100vh - 12px - 12px); }

.performance-table-container {
  max-height: calc(100vh - 50px - 50px - 12px - 12px); }

.performance-table {
  height: 1px;
  /* ignored but needed */ }

.performance-table .performance-table-head {
  white-space: nowrap; }

.performance-table .indicator-detail-chip {
  display: inline-block;
  padding: 4px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-right: 5px;
  font-weight: 400;
  font-size: 12px;
  height: unset;
  margin-bottom: 5px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1; }

.performance-table .indicator-detail-chip span {
  padding: 0; }

.performance-table .performance-table-cell {
  background-color: #fff;
  height: 100%;
  padding: 16px 0px 16px 5px; }

.performance-table .performance-indicator-cell {
  background-color: #fff;
  height: 100%;
  padding: 16px 0px 16px 5px; }

.performance-table .performance-table-cell .cell-content {
  max-width: 200px;
  position: relative; }

.performance-table .performance-indicator-cell .cell-content {
  font-weight: 700;
  margin-right: 5px;
  margin-left: 5px;
  width: 25vw; }

.performance-table .performance-table-cell .cell-content, .performance-table .performance-indicator-cell .cell-content {
  font-family: Lato, sans-serif;
  border-right: 1px solid #c6c6c6;
  border-bottom: none;
  height: 100%;
  padding-left: 10px;
  padding-right: 10px; }

.performance-table .performance-table-cell.alternate, .performance-table .performance-indicator-cell.alternate {
  background-color: #f5f5f5; }

.performance-table .performance-table-head-cell {
  background-color: #fff;
  border-radius: 5px;
  border-bottom: none;
  font-weight: 700;
  padding: 0 5px 0 0; }

.performance-table .performance-table-head-cell .cell-content {
  font-family: Lato, sans-serif;
  height: 100%;
  background-color: #f5f5f5;
  padding: 5px 5px 5px 10px;
  margin-right: 5px;
  margin-left: 5px;
  min-width: 150px; }

.performance-table .performance-table-head-cell.indicator-column-head .cell-content {
  width: 25vw;
  min-width: unset; }

.performance-table .performance-indicator-cell .cell-content .indicator-name {
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 5px; }

.performance-table .link-button {
  text-decoration: underline;
  cursor: pointer; }

.table-loading-state {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 9; }
  .table-loading-state .table-circular-progress {
    margin-top: calc((100vh - 50px - 50px - 12px - 12px - 40px) / 2); }

/* read more */
.performance-table-cell input {
  opacity: 0;
  position: absolute;
  pointer-events: none; }

.performance-table-cell span {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  overflow: hidden; }

.performance-table-cell label {
  text-decoration: underline;
  cursor: pointer; }

.performance-table-cell input:focus ~ label {
  outline: -webkit-focus-ring-color auto 5px; }

.performance-table-cell input:checked + span {
  -webkit-line-clamp: unset; }

.performance-table-cell input:checked ~ label, .performance-table-cell span:not(.truncated) ~ label {
  display: none; }

/* read more */
.filter-search, .filter-search label, .filter-search select, .filter-search option, .filter-search .filter-menu-item, .filter-search ul li {
  font-family: Lato, sans-serif; }

.filter-menu-item {
  font-size: 14px !important;
  font-family: Lato, sans-serif !important;
  padding-left: 12px !important;
  line-height: 1 !important; }

.filter-menu-item .option-text {
  font-family: Lato, sans-serif !important;
  width: 100%;
  margin-right: 16px;
  white-space: break-spaces; }

.filter-menu-item .option-text.blank-label {
  padding-bottom: 5.5px;
  padding-top: 5.5px; }

.filter-menu-item .option-facet {
  font-family: Lato, sans-serif !important;
  padding: 6px;
  height: unset;
  margin: auto; }

.filter-menu-item .option-facet span {
  padding: 0px; }

.filter-search .option-facet {
  display: none; }

.performance-modal .MuiDialog-paper {
  margin: 0 auto;
  position: absolute;
  padding: 20px;
  border-radius: 20px !important;
  min-height: 248px; }
  .performance-modal .MuiDialog-paper .performance-modal-title {
    font-family: Lato, sans-serif !important;
    font-weight: 700;
    font-size: 24px;
    line-height: 145%;
    color: #000; }
  .performance-modal .MuiDialog-paper .performance-modal-content {
    font-family: Lato, sans-serif !important;
    font-weight: 400;
    font-size: 14px;
    line-height: 145%;
    color: #000;
    margin-top: 15px; }
  .performance-modal .MuiDialog-paper .performance-modal-link {
    margin-top: 15px;
    margin-bottom: 15px; }
    .performance-modal .MuiDialog-paper .performance-modal-link a {
      font-family: Lato, sans-serif !important;
      font-weight: 400;
      font-size: 14px;
      line-height: 145%;
      color: #000; }
  .performance-modal .MuiDialog-paper .performance-modal-full {
    width: 100%; }

.performance-modal-button {
  font-family: Lato, sans-serif !important;
  line-height: 100%;
  font-size: 16px; }

#performance-table-container {
  position: relative; }

.Page-head {
  z-index: 9999; }

.download-btn {
  text-transform: none !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 100% !important;
  font-family: Lato, sans-serif !important;
  border-radius: 100px !important;
  border: 1px solid #3f3f3f !important;
  background-color: #fff !important;
  padding: 9px 12px !important;
  letter-spacing: unset !important;
  font-stretch: normal !important; }

.performance-modal .MuiDialog-paper {
  margin: 0 auto;
  position: relative;
  padding: 20px;
  border-radius: 20px !important; }
  .performance-modal .MuiDialog-paper .performance-modal-title {
    font-family: Lato, sans-serif !important;
    font-weight: 700;
    font-size: 24px;
    line-height: 145%;
    color: #000; }
  .performance-modal .MuiDialog-paper .performance-modal-content {
    font-family: Lato, sans-serif !important;
    font-weight: 400;
    font-size: 14px;
    line-height: 145%;
    color: #000;
    margin-top: 15px; }
  .performance-modal .MuiDialog-paper .performance-modal-link {
    margin-top: 15px;
    margin-bottom: 15px; }
    .performance-modal .MuiDialog-paper .performance-modal-link a {
      font-family: Lato, sans-serif !important;
      font-weight: 400;
      font-size: 14px;
      line-height: 145%;
      color: #000; }
  .performance-modal .MuiDialog-paper .performance-modal-full {
    width: 100%; }

.performance-modal-button {
  font-family: Lato, sans-serif !important;
  line-height: 100%;
  font-size: 16px; }

#performance-table-container {
  position: relative; }

.Page-head {
  z-index: 9999; }

@media (max-width: 480px) {
  .performance-table .performance-indicator-cell .cell-content {
    width: 80vw; }
  .performance-modal .MuiDialog-paper {
    width: 87%; } }

.Hero {
  background: #fdf7d5;
  color: #3f3f3f; }

.Hero-video {
  fill: white;
  color: white;
  height: 100px;
  width: 100px;
  margin-top: 2rem;
  border-radius: 50%;
  background: grey;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-direction: column;
      flex-direction: column;
  cursor: pointer;
  position: relative; }
  @media screen and (min-width: 750px) {
    .Hero-video {
      height: 170px;
      width: 170px; } }
  .Hero-video::before {
    box-sizing: content-box;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    border: 6px solid grey;
    left: -6px;
    top: -6px;
    border-radius: 50%;
    transform: scale(1);
    opacity: 1;
    transition: opacity 0.3s, transform 0.3s; }
  .Hero-video:hover::before {
    transform: scale(1.3);
    opacity: 0.3; }

.Hero-title {
  max-width: 30rem;
  text-align: center;
  font-size: 2rem; }
  @media screen and (min-width: 900px) {
    .Hero-title {
      font-size: 3rem; } }

.Hero {
  height: 25rem;
  background-position: center;
  background-size: cover;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-direction: column;
      flex-direction: column;
  background-image: url("/assets/images/hero-placeholder.jpg"); }

.Features {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  max-width: 1024px;
  margin: 0 auto; }

.Features-item {
  width: 100%; }
  @media screen and (min-width: 500px) {
    .Features-item {
      width: 50%; } }
  @media screen and (min-width: 700px) {
    .Features-item {
      width: 33.33333%; } }

.VideoSection {
  background: white;
  padding: 1rem 2rem 2rem; }

.VideoSection-heading {
  text-align: center;
  fill: #7bb344;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-direction: column;
      flex-direction: column;
  padding: 2rem 2rem 0; }

.VideoSection-list {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  max-width: 1300px;
  margin: 0 auto; }

.VideoSection-item {
  padding: 1rem;
  width: 100%; }

.EventSection {
  padding: 2rem 0 3rem; }

.EventSection-heading {
  text-align: center;
  fill: #ad3c64;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-direction: column;
      flex-direction: column;
  padding: 2rem 2rem 0; }

.EventSection-list {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  max-width: 1056px;
  margin: 0 auto;
  padding: 1rem 2rem; }

.EventSection-item {
  padding: 1rem;
  width: 100%; }
  @media screen and (min-width: 850px) {
    .EventSection-item {
      width: 33.33333%; } }

.AboutSection {
  background: white;
  padding: 1rem 2rem 4rem; }

.AboutSection-heading {
  text-align: center;
  fill: #ee9f31;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-direction: column;
      flex-direction: column;
  padding: 2rem 2rem 0; }

.AboutSection-content {
  max-width: 700px;
  margin-left: auto;
  margin-right: auto; }

.ExpenditureMultiplesSection-container {
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -ms-flex-direction: column;
      flex-direction: column; }
  @media screen and (min-width: 1000px) {
    .ExpenditureMultiplesSection-container {
      -ms-flex-flow: row wrap;
          flex-flow: row wrap; } }

.ExpenditureMultiplesSection-item {
  min-width: 0;
  padding-bottom: 1rem; }
  @media screen and (min-width: 1000px) {
    .ExpenditureMultiplesSection-item {
      width: 33.33333%;
      padding-right: 1rem; }
      .ExpenditureMultiplesSection-item--right {
        padding-left: 1rem;
        padding-right: 0; } }

.ExpenditureMultiplesSectionContent {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /* Three columns of equal width */
  gap: 16px;
  /* Space between items */
  margin: 20px;
  /* Optional margin around the grid */ }

.chart-container {
  margin-top: 20px; }

.chart-frame {
  width: 100%;
  height: 75vh;
  border: none; }

.ExpenditureMultiplesSection-share {
  max-width: 250px; }

.ExpenditureMultiplesSection-cell {
  padding: 0.25rem 1rem; }

.ExpenditureMultiplesSection-label {
  display: -ms-flexbox;
  display: flex; }
  @media screen and (max-width: 400px) {
    .ExpenditureMultiplesSection-label {
      display: block; } }

.ExpenditureMultiplesSection-labelItem {
  margin-top: 1rem;
  margin-right: 3rem; }

.AdjustedSection-container {
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -ms-flex-direction: column;
      flex-direction: column; }
  @media screen and (min-width: 1000px) {
    .AdjustedSection-container {
      -ms-flex-flow: row wrap;
          flex-flow: row wrap; } }

.AdjustedSection-item {
  min-width: 0;
  padding-bottom: 1rem; }
  @media screen and (min-width: 1000px) {
    .AdjustedSection-item {
      width: 50%;
      padding-right: 1rem; }
      .AdjustedSection-item--full {
        width: 100%; }
      .AdjustedSection-item:nth-of-type(2n) {
        padding-left: 1rem;
        padding-right: 0; } }

.AdjustedSection-share {
  max-width: 250px; }

.AdjustedSection-indicatorsGrid {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-pack: stretch;
      justify-content: stretch; }

.AdjustedSection-indicator {
  padding: 0 0.5rem 2rem;
  width: 100%; }
  @media screen and (min-width: 600px) {
    .AdjustedSection-indicator {
      width: 33.33333%; } }
  @media screen and (min-width: 1400px) {
    .AdjustedSection-indicator--small {
      width: 25%; } }

.ChangeIndicator {
  background: white;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #3f3f3f;
  height: 5rem;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  padding: 0 2rem;
  transform: translateX(-0.25rem);
  fill: #3f3f3f;
  font-weight: bold; }
  .ChangeIndicator--priority {
    background-color: #3f3f3f;
    color: white;
    fill: white;
    font-weight: normal; }

.ChangeIndicator-icon {
  transform: translateX(-0.5rem); }
  .ChangeIndicator-icon--flip {
    transform: translateX(-0.5rem) rotate(180deg); }

.ChangeIndicator-main {
  font-size: 1.75rem; }

.ChangeIndicator-secondary {
  font-size: 1rem; }

.ProgrammesSection-container {
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -ms-flex-direction: column;
      flex-direction: column; }
  @media screen and (min-width: 1000px) {
    .ProgrammesSection-container {
      -ms-flex-flow: row wrap;
          flex-flow: row wrap; } }

.ProgrammesSection-item {
  min-width: 0;
  padding-bottom: 1rem; }
  @media screen and (min-width: 1000px) {
    .ProgrammesSection-item {
      width: 43%;
      padding-right: 1rem; }
      .ProgrammesSection-item--right {
        width: 50%; } }
  @media screen and (max-width: 1000px) {
    .ProgrammesSection-item--right {
      width: calc(100% + 3rem);
      margin-left: -1.5rem; } }
  .ProgrammesSection-item--right .Card {
    height: calc(100vh - 145px); }
    .ProgrammesSection-item--right .Card iframe {
      width: 100%;
      height: 100%;
      border: 0px; }
    .ProgrammesSection-item--right .Card .legend {
      margin-top: -20px; }

.ProgrammesSection-share {
  max-width: 250px; }

.EconClassPackedCirclesSection-container {
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -ms-flex-direction: column;
      flex-direction: column; }
  @media screen and (min-width: 1000px) {
    .EconClassPackedCirclesSection-container {
      -ms-flex-flow: row wrap;
          flex-flow: row wrap; } }

.EconClassPackedCirclesSection-item {
  min-width: 0;
  padding-bottom: 1rem; }
  @media screen and (min-width: 1000px) {
    .EconClassPackedCirclesSection-item {
      width: 43%;
      padding-right: 1rem; }
      .EconClassPackedCirclesSection-item--right {
        width: 50%; } }
  @media screen and (max-width: 1000px) {
    .EconClassPackedCirclesSection-item--right {
      width: calc(100% + 3rem);
      margin-left: -1.5rem; } }
  .EconClassPackedCirclesSection-item--right .Card {
    height: calc(100vh - 145px); }
    .EconClassPackedCirclesSection-item--right .Card iframe {
      width: 100%;
      height: 100%;
      border: 0px; }
    .EconClassPackedCirclesSection-item--right .Card .legend {
      margin-top: -20px; }

.EconClassPackedCirclesSection-share {
  max-width: 250px; }

.Missing {
  max-height: 100px; }

.ProgramEconSmallMultiplesSection-container {
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -ms-flex-direction: column;
      flex-direction: column; }
  @media screen and (min-width: 1000px) {
    .ProgramEconSmallMultiplesSection-container {
      -ms-flex-flow: row wrap;
          flex-flow: row wrap; } }

.ProgramEconSmallMultiplesSection-item {
  min-width: 0;
  padding-bottom: 1rem; }
  @media screen and (min-width: 1000px) {
    .ProgramEconSmallMultiplesSection-item {
      width: 43%;
      padding-right: 1rem; }
      .ProgramEconSmallMultiplesSection-item--right {
        width: 50%; } }
  @media screen and (max-width: 1000px) {
    .ProgramEconSmallMultiplesSection-item--right {
      width: calc(100% + 3rem);
      margin-left: -1.5rem; } }
  .ProgramEconSmallMultiplesSection-item--right .Card iframe {
    width: 100%;
    /* Set initial height, but pym will take over */
    border: 0px; }
  .ProgramEconSmallMultiplesSection-item .legend {
    margin-top: -20px; }

.ProgramEconSmallMultiplesSection-share {
  max-width: 250px; }

.ExpenditurePhaseSection-container {
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -ms-flex-direction: column;
      flex-direction: column; }
  @media screen and (min-width: 1000px) {
    .ExpenditurePhaseSection-container {
      -ms-flex-flow: row wrap;
          flex-flow: row wrap; } }

.ExpenditurePhaseSection-item {
  min-width: 0;
  padding-bottom: 1rem; }
  @media screen and (min-width: 1000px) {
    .ExpenditurePhaseSection-item {
      width: 43%;
      padding-right: 1rem; }
      .ExpenditurePhaseSection-item--right {
        width: 50%; } }

.ExpenditurePhaseSection-share {
  max-width: 250px; }

.ExpenditurePhaseSection-label {
  display: -ms-flexbox;
  display: flex; }
  @media screen and (max-width: 400px) {
    .ExpenditurePhaseSection-label {
      display: block; } }

.ExpenditurePhaseSection-labelItem {
  margin-top: 1rem;
  margin-right: 3rem; }

.ExpenditureSection-container {
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -ms-flex-direction: column;
      flex-direction: column; }
  @media screen and (min-width: 1000px) {
    .ExpenditureSection-container {
      -ms-flex-flow: row wrap;
          flex-flow: row wrap; } }

.ExpenditureSection-item {
  min-width: 0;
  padding-bottom: 1rem; }
  @media screen and (min-width: 1000px) {
    .ExpenditureSection-item {
      width: 43%;
      padding-right: 1rem; }
      .ExpenditureSection-item--right {
        width: 50%; } }

.ExpenditureSection-share {
  max-width: 250px; }

.ExpenditureSection-cell {
  padding: 0.25rem 1rem; }

.PdfEmbed {
  border: 1px solid 1px solid #3f3f3f; }

.Share-title {
  padding-bottom: 10px; }

.Share-action {
  display: table; }

.Share-select {
  display: table-cell;
  width: 100%;
  padding-right: 5px;
  vertical-align: top; }

.Share-button {
  display: table-cell;
  vertical-align: top; }

.Share-dropdown {
  line-height: 1.3;
  cursor: pointer;
  padding: 9px 13px;
  font-size: 14px;
  display: block;
  border-radius: 4px;
  width: 100%; }

.LinksList {
  margin: 0;
  padding: 0;
  list-style: none;
  transform: translateX(-0.5rem); }

.LinksList-link {
  display: -ms-inline-flexbox;
  display: inline-flex;
  padding: 0.25rem 0.5rem;
  border-radius: 2px;
  fill: grey;
  text-decoration: none; }
  .LinksList-link.no-line .LinksList-title {
    text-decoration: none !important; }
  .LinksList-link--clickable:hover {
    background-color: rgba(0, 0, 0, 0.1); }
  .LinksList-link.no-pointer {
    cursor: text; }

.LinksList-icon {
  -ms-flex-negative: 0;
      flex-shrink: 0;
  margin-right: 0.5rem; }

.LinksList-title {
  -ms-flex-positive: 1;
      flex-grow: 1;
  text-decoration: underline; }

ul.InlineLinksList {
  list-style: none; }
  ul.InlineLinksList li {
    display: inline; }
    ul.InlineLinksList li a {
      padding-left: 0; }
    ul.InlineLinksList li .LinksList-icon {
      display: none; }

li.link-inline-text {
  padding-left: 36px; }

.Message {
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  padding: 1.5rem;
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
  fill: #155724; }
  .Message--secondary {
    color: #383d41;
    background-color: #e2e3e5;
    border-color: #d6d8db;
    fill: #383d41; }

.Message-heading {
  font-weight: 900;
  font-size: 18px;
  margin-bottom: 1rem;
  display: inline-block;
  margin-left: 1rem; }

.Message-content {
  display: -ms-flexbox;
  display: flex; }

.Message-text {
  line-height: 1.45;
  font-size: 14px;
  -ms-flex-positive: 1;
      flex-grow: 1; }

.Card {
  padding: 1rem;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12); }
  @media screen and (min-width: 600px) {
    .Card {
      padding: 1rem 2rem; } }
  .Card--fullHeight {
    height: 100%; }
  .Card--dark {
    background: #e2e3e5; }

.Title {
  font-weight: bold;
  font-size: 2rem; }
  .Title--section {
    font-size: 1.5rem; }
  .Title--interSection {
    font-size: 16px; }

.Icon {
  width: 1.5rem;
  height: 1.5rem;
  display: inline-block; }
  @supports (display: flex) {
    .Icon {
      position: static; } }
  .Icon--extraSmall {
    width: 0.75rem;
    height: 0.75rem; }
  .Icon--small {
    width: 18px;
    height: 18px; }
  .Icon--large {
    width: 2rem;
    height: 2rem; }
  .Icon--extraLarge {
    width: 4rem;
    height: 4rem; }

.Preview {
  background: #fff;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  padding: 1.5rem;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-direction: column;
      flex-direction: column; }
  @media screen and (min-width: 750px) {
    .Preview {
      height: 200px;
      padding: 2rem; } }
  .Preview--fullHeight {
    height: 100%; }
  .Preview--topAlign {
    -ms-flex-pack: start;
        justify-content: start; }
  .Preview--transparent {
    background: none;
    box-shadow: none; }

.Preview-heading {
  -ms-flex-item-align: start;
      align-self: flex-start;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  font-size: 18px;
  margin-bottom: 1rem;
  display: inline-block; }
  .Preview-heading--link {
    text-decoration-line: underline; }
    .Preview-heading--link:hover {
      background: #d9d9d9;
      background: rga(0, 0, 0, 1.5); }

.Preview-content {
  line-height: 1.45;
  font-size: 14px; }

.Preview-icon {
  text-align: center;
  padding-bottom: 1.5rem; }

.Preview-link:hover {
  background: #d9d9d9;
  background: rga(0, 0, 0, 1.5); }

.Preview-infoWrapper {
  display: -ms-flexbox;
  display: flex;
  fill: grey; }

.Preview-infoText {
  padding-right: 1rem; }

.ChartLabel {
  position: relative;
  left: 28px; }
  .ChartLabel::after {
    content: '';
    background: grey;
    width: 20px;
    height: 20px;
    position: absolute;
    top: -2px;
    left: -28px; }
  .ChartLabel--indexOf0::after {
    background: #efd8e0; }
  .ChartLabel--indexOf1::after {
    background: #deb1c1; }
  .ChartLabel--indexOf2::after {
    background: #ce8aa2; }
  .ChartLabel--indexOf3::after {
    background: #bd6383; }
  .ChartLabel--indexOf4::after {
    background: #ee9f31; }

.Marker {
  position: relative;
  background: #7bb344;
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: 5rem;
  height: 5rem;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  border-radius: 50%;
  fill: white;
  margin-bottom: 1rem; }
  .Marker--purple {
    background: #ad3c64; }
  .Marker--orange {
    background: #ee9f31; }
  .Marker::before {
    margin: 1;
    content: '';
    position: absolute;
    left: 0;
    top: 60%;
    width: 0;
    height: 0;
    border-bottom: 0 solid transparent;
    border-left: 2.5rem solid transparent;
    border-right: 2.5rem solid transparent;
    border-top: 3rem solid #7bb344; }
  .Marker--purple::before {
    border-top-color: #ad3c64; }
  .Marker--orange::before {
    border-top-color: #ee9f31; }

.Marker-icon {
  position: relative; }

.ChartSourceController {
  text-align: center; }

.ChartSourceController-options {
  padding-top: 16px;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin: 0 auto; }

.ChartSourceController-item {
  display: inline-block;
  padding: 0 8px; }

.ChartSourceController-text {
  display: inline-block;
  padding-left: 4px; }

.ChartSourceController-description {
  font-style: italic; }

.BarChart-button {
  text-align: center; }

.node {
  stroke: white;
  stroke-width: 2px;
  cursor: pointer; }

.selected-info {
  position: fixed;
  bottom: 10px;
  left: 10px;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 10px;
  border-radius: 5px;
  font-size: 16px;
  display: none; }

.treemap {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
  width: 100%; }

.container {
  width: 48%;
  height: 80vh; }

@keyframes homepage-hero {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

.homepage-hero {
  height: 450px; }

.homepage-hero-image {
  display: -ms-flexbox;
  display: flex;
  height: 400px;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  padding: 50px 20px;
  background: #ececec; }

.homepage-hero-loading {
  margin: 0 auto; }

.homepage-hero-spinner {
  width: 60px;
  height: 60px;
  margin: 0 auto;
  border-radius: 50%;
  border: 4px solid #79b443;
  border-right: 4px solid transparent;
  animation-name: homepage-hero;
  animation-duration: 0.5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear; }

.homepage-hero-bar {
  height: 50px;
  background: #79b443; }

.infrastructure-projects {
  background: white;
  min-height: 100vh; }

.national-treemap {
  padding: 4rem 0 2rem; }

.embed-horizontal-bar-chart .chart-header {
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -ms-flex-align: center;
      align-items: center;
  width: 100%;
  height: 50px;
  margin-bottom: 20px;
  border-bottom: 2px solid #ddd; }

.embed-horizontal-bar-chart .chart-background {
  fill: white; }

.embed-horizontal-bar-chart .item-label {
  pointer-events: none; }

.embed-horizontal-bar-chart .label-bar {
  cursor: pointer; }

.embed-horizontal-bar-chart .bar-label-percent {
  fill: #979797; }

.embed-horizontal-bar-chart .chart-header .filter-label {
  position: absolute;
  left: 5px;
  font-size: 14px; }

.embed-horizontal-bar-chart .chart-header .select-list {
  position: absolute;
  right: 0;
  width: 40%;
  height: 30px;
  font-size: 14px; }

.embed-horizontal-bar-chart .chart-content {
  position: relative;
  width: 100%; }

.embed-horizontal-bar-chart .grid line {
  stroke: lightgrey;
  stroke-opacity: 0.6; }

.embed-horizontal-bar-chart .grid path {
  stroke-width: 1; }

.embed-horizontal-bar-chart .grid text {
  fill: black; }

.embed-horizontal-bar-chart rect.bar:hover {
  /*fill: cyan;*/
  stroke: cyan;
  cursor: pointer; }

.toolTip {
  position: absolute;
  display: none;
  padding: 4px;
  min-width: 80px;
  height: auto;
  font-family: Roboto, Sans-Serif;
  color: #fff;
  font-size: 14px;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.7); }

.toolTip:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -8px;
  border-width: 8px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.7) transparent transparent transparent; }

.barChart {
  fill: #ad3c64; }

.axis-label {
  font-size: 12px; }

.axis path,
.axis line {
  fill: none;
  shape-rendering: crispEdges; }

.chart-container {
  width: 48%;
  height: 60vh; }

@media (max-width: 768px) {
  .chart-container {
    width: 100%;
    height: 50vh; } }

.bubbleChartMainContainer {
  display: -ms-flexbox;
  display: flex;
  gap: 40px; }

.bubbleChartMainContainer label {
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: bold;
  background: #000;
  color: #fff;
  width: 75%;
  padding: 3px; }

.bubbleChartLegend {
  font-size: 10px;
  padding-top: 10px;
  margin-left: 30px;
  width: 100%; }

.bubbleChartContainer p:nth-child(2) {
  font-family: Roboto, sans-serif;
  font-size: 13px;
  font-weight: bold; }

.bubbleChartContainer p:nth-child(4) {
  font-family: Roboto, sans-serif;
  font-size: 13px;
  font-weight: bold; }

.bubbleChartLegendContainer {
  border-right: 1px solid lightgrey;
  padding-right: 50px;
  height: 100px; }

.treemapWrapper {
  display: -ms-flexbox;
  display: flex;
  /* Use flexbox to align items horizontally */
  -ms-flex-pack: distribute;
      justify-content: space-around;
  /* Evenly space treemaps */
  -ms-flex-align: start;
      align-items: flex-start;
  /* Align treemaps at the top */ }

.treemapContainer {
  -ms-flex: 1;
      flex: 1;
  /* Allow equal distribution */
  text-align: start;
  /* Center the title */ }

.treemapContainer label {
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: bold;
  background: #000;
  color: #fff;
  width: 75%;
  padding: 3px; }

.treemapContainer p:nth-child(2) {
  font-family: Roboto, sans-serif;
  font-size: 11px; }

.treemapContainer p:nth-child(3) {
  font-family: Roboto, sans-serif;
  font-size: 13px;
  font-weight: bold; }

.treemap {
  width: 100%;
  height: 400px;
  /* Adjust as needed */ }

.horizontalBarChart {
  fill: gray; }

/* Ensure the container takes full width and adjusts based on content */
.dropdown-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  gap: 15px;
  /* Space between dropdown items */
  width: 100%; }

/* Align label and select element properly */
.dropdown-item {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: start;
      align-items: flex-start;
  /* Align items to the start of the container */ }

/* Ensure select boxes fill available space */
.PseudoSelect select {
  width: 100%;
  /* Make sure select fills the container */
  padding: 8px;
  font-size: 14px;
  border-radius: 4px;
  border: 1px solid #ccc; }

/* Optional: Adding some styles for the wrapper elements for better UI */
.DeptControls-itemWrap {
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between; }

/* For dropdown elements, make sure select boxes have a consistent width */
.PseudoSelect {
  width: 100%; }

.horizontalBarGraph {
  width: auto;
  padding-top: 20px; }

.horizontalBarGraph svg {
  width: auto;
  height: auto; }

.bar {
  stroke: black;
  stroke-width: 1px; }

.bar:hover {
  opacity: 0.8; }

.axis-label {
  font-size: 12px; }

.legend {
  font-size: 14px;
  cursor: pointer;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  margin-right: 10px; }

.legend-box {
  width: 15px;
  height: 15px;
  display: inline-block;
  margin-right: 5px; }

#legend {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-top: 10px; }

.chart-container {
  margin: 50px; }

.chart-title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px; }
